import {createApp} from 'vue'
import App from '@/App.vue'
import store from '@/store'
import 'bootstrap-icons/font/bootstrap-icons.css' 
 
import router from '@/router'
import '@/assets/css/nucleo-icons.css'
import '@/assets/css/nucleo-svg.css'
import ArgonDashboard from './dashboard'
import '@/axios'
import i18n from '@/i18n'
import '@fortawesome/fontawesome-free/css/all.css';








const appInstance = createApp(App).use(i18n).use(router).use(store).use(store)
appInstance.use(store)
appInstance.use(router)
appInstance.use(ArgonDashboard)
appInstance.mount('#app')
