<template>
  <div ref="editor" class="quill-editor"></div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'RichTextEditor',
  props: ['value'],
  mounted() {
    this.editor = new Quill(this.$refs.editor, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'image'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }]
        ]
      }
    });
    this.editor.on('text-change', () => {
      this.$emit('input', this.editor.root.innerHTML);
    });
    this.editor.root.innerHTML = this.value;
  },
  watch: {
    value(newValue) {
      if (newValue !== this.editor.root.innerHTML) {
        this.editor.root.innerHTML = newValue;
      }
    }
  }
};
</script>

<style>
.quill-editor {
  height: 300px;
}
</style>