import axios from "axios";
import api_config from "@/config";

const REST_ENDPOINT = api_config.API_ENDPOINT;

export function validateEmptyData() {
  return "can not be null";
}

export function successCreation() {
  return "has been created successfully";
}

// ========================= get school info ======================




export async function getSchoolinfo(schoolId) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/get_school_info",
      method: "GET",
      params: {
        school_id: schoolId,
      },
    });
    if (res.status === 200) {
      response.status = res.status;
      response.data = res.data;
    }
  } catch (err) {
    response.status = err.response.status;
    console.error(
      "Erreur lors de la récupération des informations de l'école :",
      err
    );
  }
  return response;
}

// ============ get grade code =============================

export async function getGradeCode() {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/grades",
      method: "GET",
      params: {
        training_type: localStorage.getItem("training_type"),
        education_grade: localStorage.getItem("education_grade"),
        education_system: localStorage.getItem("education_system"),
      },
    });
    if (res.status === 200) {
      response.status = res.status;
      response.data = res.data;
    }
  } catch (err) {
    response.status = err.response.status;
    console.error("Caught an error during GET grades:", err);
  }
  return response;
}

// =============== get speciality ====================

export async function getSpeciality() {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/specialities",
      method: "GET",
      params: {
        training_type: localStorage.getItem("training_type"),
        education_grade: localStorage.getItem("education_grade"),
        education_system: localStorage.getItem("education_system"),
      },
    });
    if (res.status === 200) {
      response.data = res.data;
    }
  } catch (err) {
    response.status = err.response.status;
    console.error("Caught an error during GET specialities:", err);
  }
  return response;
}

//============= create classroom ======================

export async function createClassroom(data) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/classrooms/",
      method: "POST",
      data: data,
    });
    if (res.status === 201) {
      response.status = res.status;
      response.data = res.data;
    }  if (res.status === 403) {
      response.data = res.data;
    }
  } catch (err) {
    response.status = err.response.status;
    console.error("Caught an error during creation of classroom:", err);
  }
  return response;
}

//=========== delete classroom ===================



 

export async function deleteClassroom(classroom_Id) {
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/classrooms/${classroom_Id}`,
      method: "DELETE",
    });

    if (res.status === 204) {
      return true;
    }
  } catch (err) {
    console.error("Caught an error during DELETE classroom:", err);
    throw err;
  }
}

// ============ update classroom =================

export async function updateClassroom(classroom_Id, updatedData) {
  let response = {};

  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/classrooms/${classroom_Id}`,
      method: "PUT",
      data: updatedData,
    });

    if (res.status === 200) {
      response.status = res.status;
      response.data = res.data;
    }
  } catch (err) {
    response.status = err.response.status;
    console.error("Caught an error during updating classroom:", err);
  }

  return response;
}

// ============= get classroom by id ==============


 

export async function getClassroomById(classroomsId) {
  try {
    const res = await axios.get(
      `${REST_ENDPOINT}/api/classrooms/${classroomsId}`
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.error("Caught an error during GET classroom:", error);
    throw MediaError;
  }
}

// ============= Create members ================

export async function createMembers(data) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/auth/members/",
      method: "POST",
      data: data,
    });
    console.log(res);

    response.status = res.status;
    response.data = res.data;

    return response;
  } catch (err) {
    if (err.response) {
      response.status = err.response.status;
      response.error = err.response.data;
      console.error("Caught an error during creation of account:", err);
    } else if (err.request) {
      console.error("Network error during creation of account:", err);
    } else {
      console.error("Unexpected error during creation of account:", err);
    }

    return response;
  }
}

// ============== get members =====================

export async function getMembers() {
  try {
    const response = await axios.get(`${REST_ENDPOINT}/auth/members`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : "Internal Server Error",
    };
  }
}

 

export async function getinfoMembers(username) {
  try {
    const res = await axios.get(`${REST_ENDPOINT}/auth/members/`, {
      params: { username: username }
    });

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error("Caught an error during GET member info:", err);
    throw err;
  }
}


export async function updateMemberInfo(username, updatedData) {
  try {
    const res = await axios.patch(`${REST_ENDPOINT}/auth/members/${username}/`, updatedData);

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error("Caught an error during PATCH member info:", err);
    throw err;
  }
}




// Fonction pour mettre à jour le statut is_active d'un membre
 

export async function updateMemberStatus(memberId, updatedData) {
  try {
    const res = await axios.patch(
      `${REST_ENDPOINT}/auth/members/${memberId}`,
      updatedData
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error("Caught an error during PATCH member:", err);
    throw err;
  }
}

// ============== delete members =====================

export async function deletemember(membersId) {
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/auth/members/${membersId}`,
      method: "DELETE",
    });

    if (res.status === 204) {
      return true;
    }
  } catch (err) {
    console.error("Caught an error during DELETE member:", err);
    throw err;
  }
}





//============= upload File ====================

export async function uploadFile(file) {
  let response;
  let formData = new FormData();
  formData.append("file", file);
  try {
    response = await axios({
      url: REST_ENDPOINT + "/api/upload_file/",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  } catch (err) {
    response = err;
    console.error("Caught an error during upload file:", err);
  }
  return response.data;
}

//================================= create student ===================

export async function createStudent(data) {
  let student = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/student/",
      method: "POST",
      data: data,
    });
    if (res.status === 201) {
      student.status = res.status;
      student.data = res.data;
    }
    if (status.code === 400) {
      student.status = res.status;
      student.data = res.data;
    }
  } catch (err) {
    student = err.response;
    console.error("Caught an error during create student:", err);
  }
  return student;
}

//============= delete students ======================

export async function deleteStudent(studentId) {
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/student/${studentId}`,
      method: "DELETE",
    });

    if (res.status === 204) {
      return true;
    }
  } catch (err) {
    console.error("Caught an error during DELETE students:", err);
    throw err;
  }
}

// ==================update student =================

export async function updateStudent(studentId, updatedData) {
  try {
    const res = await axios.patch(
      `${REST_ENDPOINT}/api/student/${studentId}`,
      updatedData
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error("Caught an error during PATCH students:", err);
    throw err;
  }
}

// ==================get student =================
export async function getStudent(studentId) {
  try {
    const res = await axios.get(`${REST_ENDPOINT}/api/student/${studentId}`);

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error("Caught an error during GET student:", err);
    throw err;
  }
}

//============ get student classroom =======================

export async function getStudentClassrooms(params) {
  let response;
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/student_classroom",
      method: "GET",
      params: params,
    });
    if (res.status === 200) {
      response = res;
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET student classroom:", err);
  }
  return response;
}

//============ change statut student =======================

export async function statutstudents(data) {
  try {
    const res = await axios({
      url: REST_ENDPOINT + "/api/update_payment_status",
      method: "POST",
      data: data,
    });

    console.log(res);

    return {
      status: res.status,
      data: res.data,
    };
  } catch (err) {
    if (err.response) {
      console.error("Caught an error during creation of installment:", err);
      return {
        status: err.response.status,
        error: err.response.data,
      };
    } else if (err.request) {
      console.error("Network error during creation of installment:", err);
    } else {
      console.error("Unexpected error during creation of installment:", err);
    }

    throw err;
  }
}



//============ change statut student =======================

export async function applyotherpayment(data) {
  try {
    const res = await axios({
      url: REST_ENDPOINT + "/api/create_payment_with_status",
      method: "POST",
      data: data,
    });

    console.log(res);

    return {
      status: res.status,
      data: res.data,
    };
  } catch (err) {
    if (err.response) {
      console.error("Caught an error during creation of installment:", err);
      return {
        status: err.response.status,
        error: err.response.data,
      };
    } else if (err.request) {
      console.error("Network error during creation of installment:", err);
    } else {
      console.error("Unexpected error during creation of installment:", err);
    }

    throw err;
  }
}

//============ batchCreateStudent  =======================

export async function batchCreateStudent(data) {
  let response;
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/batch_create_students/",
      method: "POST",
      data: data,
    });
    if (res.status === 201) {
      response = res;
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET specialities:", err);
  }
  return response;
}

//============ Create config  =======================

export async function createconfig(data) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/payments-config/",
      method: "POST",
      data: data,
    });
    console.log(res);

    response.status = res.status;
    response.data = res.data;

    return response;
  } catch (err) {
    if (err.response) {
      response.status = err.response.status;
      response.error = err.response.data;
      console.error("Caught an error during creation of account:", err);
    } else if (err.request) {
      console.error("Network error during creation of account:", err);
    } else {
      console.error("Unexpected error during creation of account:", err);
    }

    return response;
  }
}

//============ update config  =======================

export async function updateConfig(configId, data) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + `/api/payments-config/${configId}`,
      method: "PUT",
      data: data,
    });
    console.log(res);

    response.status = res.status;
    response.data = res.data;

    return response;
  } catch (err) {
    if (err.response) {
      response.status = err.response.status;
      response.error = err.response.data;
      console.error("Caught an error during update of configuration:", err);
    } else if (err.request) {
      console.error("Network error during update of configuration:", err);
    } else {
      console.error("Unexpected error during update of configuration:", err);
    }

    return response;
  }
}

//============ get config by id  =======================

export async function getconfig(configtId) {
  try {
    const res = await axios.get(`${REST_ENDPOINT}/api/payments-config/${configtId}`);

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error("Caught an error during GET student:", err);
    throw err;
  }
}

//============ get config  =======================

export async function getcreateconfig() {
  let response;
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/payments-config/",
      method: "GET",
      
    });
    if (res.status === 200) {
      response = res;
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET student classroom:", err);
  }
  return response;
}

// =========== remove Installment By id ============

export async function removeInstallmentById(installmentId) {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${REST_ENDPOINT}/api/payments-config/${installmentId}/`,
    });

    if (response.status === 204) {
      console.log("Installment removed successfully");
    } else {
      console.error("Unexpected status code:", response.status);
    }

    return response;
  } catch (error) {
    console.error("Error during removeInstallmentById:", error);
    throw error;
  }
}

// =============== getCreatConfigdetail ===================

export async function getCreateConfigDetails(payment_config_id) {
  let response;
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/payments-config/${payment_config_id}`,
      method: "GET",
    });

    if (res.status === 200) {
      response = res.data;
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET createconfig details:", err);
  }
  return response;
}

//======================== delete config   =====================

export async function deleteConfig(configId) {
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/payments-config/${configId}`,
      method: "DELETE",
    });

    if (res.status === 204) {
      return true;
    }
  } catch (err) {
    console.error("Caught an error during DELETE configuration:", err);
    throw err;
  }
}

//=============== Submit installment ==================

export async function submitInstallments(data) {
  try {
    const res = await axios({
      url: REST_ENDPOINT + "/api/batch_create_installments/",
      method: "POST",
      data: data,
    });

    console.log(res);

    return {
      status: res.status,
      data: res.data,
    };
  } catch (err) {
    if (err.response) {
      console.error("Caught an error during creation of installment:", err);
      return {
        status: err.response.status,
        error: err.response.data,
      };
    } else if (err.request) {
      console.error("Network error during creation of installment:", err);
    } else {
      console.error("Unexpected error during creation of installment:", err);
    }

    throw err;
  }
}

// ========= Update installment =======================

export async function updateInstallment(installmentId, updatedData) {
  try {
    const res = await axios({
      url: REST_ENDPOINT + `/api/payments-installment/${installmentId}/`,
      method: "PUT",
      data: updatedData,
    });

    console.log(res);

    return {
      status: res.status,
      data: res.data,
    };
  } catch (err) {
    if (err.response) {
      console.error("Caught an error during update of installment:", err);
      return {
        status: err.response.status,
        error: err.response.data,
      };
    } else if (err.request) {
      console.error("Network error during update of installment:", err);
    } else {
      console.error("Unexpected error during update of installment:", err);
    }

    throw err;
  }
}

// ======================= get installment =====================

export async function getInstallment(params) {
  let response;
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/payments-installment/",
      method: "GET",
      params: params,
    });
    if (res.status === 200) {
      response = res;
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET student classroom:", err);
  }
  return response;
}

//  ============================ detele installment ====================

export async function deleteInstallment(installmentId) {
  let response;

  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/payments-installment/${installmentId}`,
      method: "DELETE",
    });

    if (res.status === 200) {
      response = res;
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during DELETE installment:", err);
  }

  return response;
}

// ============ get installment Config ================

export async function getinstallmentConfig(params) {
  let response;
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/payments-installment/",
      method: "GET",
      params: params,
    });
    if (res.status === 200) {
      response = res;
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET student classroom:", err);
  }
  return response;
}

// ================== application de la config a une class ===================

export async function applyPaymentConfig(data) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/classroom-payment-installment/",
      method: "POST",
      data: data,
    });
    console.log(response.data);
    console.log(res);

    response.status = res.status;
    response.data = res.data;

    return response;
  } catch (err) {
    if (err.response) {
      response.status = err.response.status;
      response.error = err.response.data;
      console.error("Caught an error during creation of account:", err);
    } else if (err.request) {
      console.error("Network error during creation of account:", err);
    } else {
      console.error("Unexpected error during creation of account:", err);
    }

    return response;
  }
}

// =========================== get congig ===========================

export async function getclassroomsconfig() {
  let response;
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/classroom-payment-installment/`,
      method: "GET",
    });

    if (res.status === 200) {
      response = res.data;
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET createconfig details:", err);
  }
  return response;
}

// ==============  get installment classroom config =======================

export async function getInstallmentsForClassroom(classroom_id) {
  let response;
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/classroom-payment-installment/?classroom_id=${classroom_id}`,
      method: "GET",
    });
    if (res.status === 200) {
      response = res.data;
      console.log(response);
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET createconfig details:", err);
  }
  return response;
}

// ================== application de la config a une class =====================

export async function applyinstallmentstudents(data) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/batch_create_payments/",
      method: "POST",
      data: data,
    });
    console.log(response.data);
    console.log(res);

    response.status = res.status;
    response.data = res.data;

    return response;
  } catch (err) {
    if (err.response) {
      response.status = err.response.status;
      response.error = err.response.data;
      console.error("Caught an error during creation of account:", err);
    } else if (err.request) {
      console.error("Network error during creation of account:", err);
    } else {
      console.error("Unexpected error during creation of account:", err);
    }

    return response;
  }
}

 

//=========================== send invoice ==========================

export async function sendinvoice(data) {
  try {
    const res = await axios({
      url: REST_ENDPOINT + "/api/send_invoices",
      method: "POST",
      data: data,
    });

    console.log(res);

    return {
      status: res.status,
      data: res.data,
    };
  } catch (err) {
    if (err.response) {
      console.error("Caught an error during creation of installment:", err);
      return {
        status: err.response.status,
        error: err.response.data,
      };
    } else if (err.request) {
      console.error("Network error during creation of installment:", err);
    } else {
      console.error("Unexpected error during creation of installment:", err);
    }

    throw err;
  }
}

// ============================send invoice ==================

export async function memberinfo(data) {
  try {
    const res = await axios({
      url: REST_ENDPOINT + "/api/send_member_info",
      method: "POST",
      data: data,
    });

    console.log(res);

    return {
      status: res.status,
      data: res.data,
    };
  } catch (err) {
    if (err.response) {
      console.error("Caught an error during creation of installment:", err);
      return {
        status: err.response.status,
        error: err.response.data,
      };
    } else if (err.request) {
      console.error("Network error during creation of installment:", err);
    } else {
      console.error("Unexpected error during creation of installment:", err);
    }

    throw err;
  }
}

// ===================== get dashboard report ========================

export async function getdashboardreport() {
  let response;
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/dashboard_report`,
      method: "GET",
    });

    if (res.status === 200) {
      response = res.data;
      console.log("Réponse de getdashboardreport:", response);
    }
  } catch (err) {
    response = err.response;
    console.error("Erreur lors de l'appel de getdashboardreport:", err);
  }
  return response;
}

// ============== get class detail ============================

 

export async function getclassroomreport(classroom_id) {
  let response;
  try {
    const res = await axios.get(`${REST_ENDPOINT}/api/report?classroom_id=${classroom_id}`);
    if (res.status === 200) {
      response = res.data;
      console.log(response);
    }
  } catch (err) {
    response = err.response;
    console.error("Caught an error during GET report classroom :", err);
  }
  return response;
}


// ========================= get financial transaction ======================

 
export async function getTransactions(xpay_connection_id, starting_on, ending_on) {
  
  try {
    const response = await axios.get(`${REST_ENDPOINT}/api/transaction_log`, {
       
      params: {
        xpay_connection_id: xpay_connection_id,
        starting_on:starting_on,
        ending_on:ending_on,
           
      }
    });
    return response.data; // Return the data directly
  } catch (error) {
    console.error("Error fetching transaction data:", error);
    throw error; // Re-throw the error to handle it outside this function
  }
}



export async function getTransfer(xpay_connection_id, starting_on, ending_on) {
  try {
    const response = await axios.get(`${REST_ENDPOINT}/api/transaction_log`, {
      params: {
        xpay_connection_id: xpay_connection_id,
        starting_on:starting_on,
        ending_on:ending_on,
         
      }
    });
    return response.data; // Return the data directly
  } catch (error) {
    console.error("Error fetching transaction data:", error);
    throw error; // Re-throw the error to handle it outside this function
  }
}


 



// ========================= Post other installment ======================


export async function createotherconfig(data) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/other-payments/",
      method: "POST",
      data: data,
    });
    console.log(res);

    response.status = res.status;
    response.data = res.data;

    return response;
  } catch (err) {
    if (err.response) {
      response.status = err.response.status;
      response.error = err.response.data;
      console.error("Caught an error during creation other payment:", err);
    } else if (err.request) {
      console.error("Network error during creation other payment:", err);
    } else {
      console.error("Unexpected error during creation other payment:", err);
    }

    return response;
  }
}

 

// ==============Get other installment =====================

export async function getotherconfig() {
  try {
    const response = await axios.get(`${REST_ENDPOINT}/api/other-payments/`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : "Internal Server Error",
    };
  }
}

// ==============Delete other installment =====================


export async function deleteotherconfig(otherconfigId) {
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/other-payments/${otherconfigId}`,
      method: "DELETE",
    });

    if (res.status === 204) {
      return true;
    }
  } catch (err) {
    console.error("Caught an error during DELETE other payment:", err);
    throw err;
  }
}

// ==============Update other installment =====================

export async function updateotherpayment(otherconfigId, data) {
  let response = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + `/api/other-payments/${otherconfigId}`,
      method: "PUT",
      data: data,
    });
    console.log(res);

    response.status = res.status;
    response.data = res.data;

    return response;
  } catch (err) {
    if (err.response) {
      response.status = err.response.status;
      response.error = err.response.data;
      console.error("Caught an error during update of configuration:", err);
    } else if (err.request) {
      console.error("Network error during update of configuration:", err);
    } else {
      console.error("Unexpected error during update of configuration:", err);
    }

    return response;
  }
}



//============ get config by id  =======================

 
export async function getPayment(otherconfigId) {
  try {
    const response = await axios.get(REST_ENDPOINT + `/api/other-payments/${otherconfigId}`);
    return response;
  } catch (error) {
    console.error("Error fetching payment data:", error);
    throw error;
  }
}


// ==============Get registernumber  =====================

export async function getregisternumber(data) {
  let response = {}
  try {
    let res = await axios.post(`${REST_ENDPOINT}/api/get_registration_number`, {
        registration_number: data["registration_number"],
        is_new_student: data["is_new_student"],
      },
    );
      response = res.data   
  } catch (error) {
    response.status = error.response ? error.response.status : 500;
    response.error = error.response ? error.response.data : "Internal Server Error";
  }
  return response;
}
// ==============Get registernumber list =====================

export async function getregisternumberslist(data) {
  let response = {}
  try {
    let res = await axios.post(`${REST_ENDPOINT}/api/generate_registration_number_list`, {
        registration_number: data["registration_number"],
        is_new_student: data["is_new_student"],
        count:data["count"]
      },
    );
      response = res.data   
  } catch (error) {
    response.status = error.response ? error.response.status : 500;
    response.error = error.response ? error.response.data : "Internal Server Error";
  }
  return response;
}

// ==============Get connection =====================

 
export async function getconnection(data) {
  let response = {}
  try {
    let res = await axios.post(`${REST_ENDPOINT}/api/get_xpay_connection`, {
      school_id: data.school_id,
      allow_split_payment: data.allow_split_payment,
        
      },
    );
      response = res.data   
  } catch (error) {
    response.status = error.response ? error.response.status : 500;
    response.error = error.response ? error.response.data : "Internal Server Error";
  }
  return response;
}


export async function nopay(data) {
  let student = {};
  try {
    let res = await axios({
      url: REST_ENDPOINT + "/api/get_xpay_connection",
      method: "POST",
      data: data,
    });
    if (res.status === 201) {
      student.status = res.status;
      student.data = res.data;
    }
    if (status.code === 400) {
      student.status = res.status;
      student.data = res.data;
    }
  } catch (err) {
    student = err.response;
    console.error("Caught an error during create student:", err);
  }
  return student;
}


// ==============Post receipt verified =====================

 
export async function postreceiptverified(data) {
  let response = {}
  try {
    let res = await axios.post(`${REST_ENDPOINT}/api/notify_receipt_verified`, {
      payment_id: data.payment_id,
      verification_status: data.verification_status,
      message: data.message,
        
      },
    );
      response = res.data   
  } catch (error) {
    response.status = error.response ? error.response.status : 500;
    response.error = error.response ? error.response.data : "Internal Server Error";
  }
  return response;
}



// ===================== get notifiactions ========================

export async function getnotifications() {
  let response;
  try {
    const res = await axios({
      url: `${REST_ENDPOINT}/api/notifications/`,
      method: "GET",
    });

    if (res.status === 200) {
      response = res.data;
      console.log("Réponse de notifications:", response);
    }
  } catch (err) {
    response = err.response;
    console.error("Erreur lors de l'appel de notifications:", err);
  }
  return response;
}


 

