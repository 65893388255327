<template>
  <div class="py-4 container">
    <div class="row mt-3 mb-4 text-center">
      <h5 class="mb-5">{{ $t("new_class.title") }}</h5>

      <div class="row mt-3">
        <div class="col-md-6 col-xs-6 col-lg-7 content text-start">
          <div class="p-3 card w-">
            <div class="card-header pb-0"></div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="mb-4" v-if="GRADE_CODE.length">
                <p style="transform: translateY(13px)" class="small">
                  {{ $t("new_class.grade") }}
                </p>
                <select
                  class="form-control form-select"
                  style="height: 50px"
                  v-model="grade_code"
                >
                  <option
                    v-for="item in GRADE_CODE"
                    :key="item.code"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="mb-4" v-if="SPECIALITY_CODE.length">
                <p style="transform: translateY(13px)" class="small">
                  {{ $t("new_class.speciality") }} 
                </p>
                <select
                  class="form-control form-select"
                  style="height: 50px"
                  v-model="speciality"
                >
                  <option
                    v-for="item in SPECIALITY_CODE"
                    :key="item.code"
                    :value="item.short_name"
                  >
                    {{ item.short_name }} - {{ item.title }}
                  </option>
                  <option>{{ $t("new_class.no_speciality") }}</option>
                </select>
              </div>
              <div class="mb-4">
                <p style="transform: translateY(13px)" class="small">
                  {{ $t("new_class.number") }} <em style="color: #f60000">*</em>
                </p>

                <argon-input
                    :class="{'input-error': showError && !number}"
                  class="form - control"
                  required
                  type="text"
                  placeholder="ex: 1 or  A"
                  v-model="number"
                ></argon-input>
              </div>
              <div class="mb-4">
                <p style="transform: translateY(13px)" class="small">
                  {{ $t("new_class.student_count") }}
                </p>
                <argon-input
                  :class="form - control"
                  required
                  type="number"
                  v-model="student_count"
                ></argon-input>
              </div>
              <div class="mt-5">
                <div class="row">
                   
                  <div class="col-md-6">
                    <argon-button
                      :loading="loading"
                      :active="buttonActive || showConfirmation"
                      fullWidth
                      color="success"
                      variant="gradient"
                      class="my-4 mb-2"
                      :clickEvent="button"
                      :disabled="loading"
                      size="lg"
                      @click="createClassroom"
                    >
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput";

import { createClassroom, getGradeCode, getSpeciality } from "@/utils/services";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "NewClass",
  components: { ArgonButton, ArgonInput },
  data() {
    return {
      GRADE_CODE: [],
      SPECIALITY_CODE: [],
      grade_code: "",
      speciality: "",
      grade_name: "",
      number: "",
      student_count: 0,
      button: "",
      successMessage: "",
      failedMessage: "",
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      showError: false,
    };
  },

  methods: {
    async getData() {
      let grades = await getGradeCode();
      this.GRADE_CODE = grades.data;
      this.grade_code = grades.data[0].name;
      this.grade_name = grades.data[0].name;
      let specialities = await getSpeciality();
      this.SPECIALITY_CODE = specialities.data;
      

      if (specialities.data.length > 0 && specialities.data[0].short_name) {
        this.speciality = specialities.data[0].short_name;
      } else {
        this.speciality = null;
      }
    },
    async createClassroom() {
      this.showError = false;
      if (this.loading) {
        // Si la soumission est déjà en cours, ne rien faire
        return;
      }

      if (!this.number) {
          this.showError = true;
          return;
        }
      this.loading = true;

      // Désactivez le bouton
      this.buttonActive = true;

      if (!navigator.onLine) {
        // Gérez le cas où il n'y a pas de connexion Internet
        const message = this.$t("message.notinternet");
        toast.error(message, {
          autoClose: 3000,
        });

        // Réactivez le bouton
        this.buttonActive = false;
      } else {
        try {
          this.loading = true;

          let data = {
            number: this.number,
            speciality_code: this.speciality,
            grade_code: this.grade_code,
            student_count: parseInt(this.student_count),
          };

          // Effectuez l'appel API pour créer la salle de classe
          let response = await createClassroom(data);

          if (response.status === 404) {
            // Gérez le cas où l'utilisateur n'est pas trouvé
            const message = this.$t("message.user404");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (response.status === 403) {
            const message =
              "Impossible de Créer la classe car elle existe déja";
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (response.status === 201) {
            // Gérez le cas où la salle de classe est créée avec succès

            if (!this.showConfirmation) {
              this.showConfirmation = true;

              // Affichez le message de réussite
              const message = "Classe ajoutée avec succès";
              toast.success(message, {
                autoClose: 3000,
              });

              // Redirigez vers la route '/classes' après un délai
              setTimeout(() => {
                this.showConfirmation = false;
                this.$router.push("/classes");
              }, 4000);
            }
          }
        } catch (error) {
          // Gérez d'autres erreurs si nécessaire
          console.error(
            "Erreur lors de la création de la salle de classe :",
            error
          );
        } finally {
          // Arrêtez le spinner de chargement
          this.loading = false;

          // Réactivez le bouton
          this.buttonActive = false;
        }
      }
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>

<style scoped>
.content {
  margin-left: auto;
  margin-right: auto;
}

.input-error {
  border: 1px solid red;
  border-radius: 10px;
}
</style>
