<template>
  <div class="card mt-4 pb-4" style="height: 240px;">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 px-1 d-flex align-items-center">
          <h6 class="mb-0">{{ $t("classdetails.receipt") }}</h6>
        </div>
        <div class="col-6 text-end px-1" v-if="receipt_table.length > 0">
          <router-link
            :to="{
              name: 'classroom-receipts',
              params: { classId: classroom_id },
            }"
          >
            <argon-button color="success" size="sm" variant="outline"
              >{{ $t("button.See-more") }}
            </argon-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body p-0 pb-0 mb-0">
      <spinner
        class="text-center mt-4 mb-8"
        v-if="loading"
        style="height: 200px"
      >
        <div>
          <span class="mt-4"><Spinner></Spinner></span>
        </div>
      </spinner>
      <table class="table px-0 mt-4 mb-6" v-else-if="receipt_table.length > 0">
        <thead>
          <tr   style="border-bottom: 1px solid rgba(255, 255, 255, 0.1); font-size:0.85rem;">
            <th scope="col" class="px-2  border-0" >{{ $t("classdetails.name") }}</th>
            <th scope="col" class="text-center border-0">{{ $t("classdetails.receipt") }}</th>
            <th scope="col" class="text-end px-2 border-0">{{ $t("classdetails.number") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(list, index) in receipt_table.slice(-4)" :key="index"   style="border-bottom: 1px solid rgba(255, 255, 255, 0.1);" >
            <td scope="row" class=" border-0" style="font-size:0.85rem;">
              {{ list.first_name }} <br>
              {{ list.first_name }}
            </td>

            <td class="text-center border-0 pt-4">
              <a
                title="Cliquer pour visualier le reçu"
                class="opacity-6"
                target="_blank"
                :href="list.receipt_url"
                style="font-size:0.85rem;"
                
              >
                <i class="fas fa-file-pdf" aria-hidden="true"></i> Receipt</a
              >
            </td>
            <td class="text-end  position-relative border-0 pt-4">
              <span class="amount1" style="font-size:0.85rem;">
                {{ list.registration_number }}
              </span>
              <!--
              <div class="action position-absolute top-0 mx-4" >
               
                  <router-link
                    :to="{
                      name: 'detail-config',
                      params: { id: list.id },
                    }"
                  >
                    <button
                      class="btn btn-link text-secondary mb-0 text-xl"
                      title="Créer des tranches"
                    >
                      <i class="fa fa-plus text-success" aria-hidden="true"></i>
                    </button>
                  </router-link>
                  

                <button
                  class="btn btn-link text-secondary mb-0"
                  title="Detail du paiement"
                  data-bs-toggle="modal"
                  data-bs-target="#deletepaymentModal"
                >
                  <i
                    class="fa fa-eye text-success"
                    style="font-size: 18px; transform: translateY(8px)"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              -->
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="no-receipt" v-else>
        <div class="text-recus">
          <p class="w-70 mt-3" style="margin: 0 auto">
            {{ $t("classdetails.receipt_text") }}
          </p>
          <img  class="image mt-3" src="@/assets/img/reçu.png" alt="" />
        </div>
      </div>
    </div>

     


  </div>
  <div class="card mt-3 h-50">
      <div class="card-header">
        <div class="col-6 px-1 d-flex align-items-center">
          <h6 class="mb-0">Suivi Scolaire</h6>
        </div>
      </div>
      <div class="card_body">
        <div class="text-recus">
          <img  class="image mt-3" src="@/assets/img/suivi.png" alt="" />
           
        </div>
      </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { getclassroomreport } from "@/utils/services";
import Spinner from "../components/Spinner.vue";

export default {
  data() {
    return {
      receipts: [],
      receipt_table: [],
      loading: false,
    };
  },
  name: "receipt-card",
  components: {
    ArgonButton,
    Spinner,
  },

  props: {
    classroom_id: {
      type: Text,
    },
  },
  mounted() {
    this.fetchClassroomReport(this.classroom_id);
  },
  methods: {
    async fetchClassroomReport(classroom_id) {
      try {
        this.loading = true;
        // Appeler la fonction pour récupérer les informations de la classe
        const response = await getclassroomreport(classroom_id);
        if (response) {
          this.classroomReport = response;

          this.receipt_table = this.classroomReport.student_receipt;
        }
      } catch (error) {
        console.error("Error fetching classroom report:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.text-recus {
  text-align: center;
}
.text-recus p {
   font-size:14px;
   width: 40%;
}
.text-recus .image {
  width: 110px;
}
</style>
