export default {
  "help": {
    "check_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check our docs"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help ?"])}
  },
  "sidebar": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access control"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classrooms"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Params"])},
    "other-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payments"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schooling"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FINANCIAL OPERATIONS"])},
    "scolarité": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuition"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help ?"])},
    "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
  },
  "button": {
    "See-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "add_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New installment"])},
    "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the group"])},
    "modify_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the group"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import (excel file)"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invoice"])},
    "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New student"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel file"])},
    "add_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New students"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "dashboard": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "title-card-payemnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment awaiting confirmation"])},
    "title-no-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment"])},
    "text-no-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts sent by parents will appear here"])},
    "total-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total "])},
    "pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "stats": {
      "money": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total "])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payment"])}
      },
      "users": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceeded deadline"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment  overdue"])}
      },
      "clients": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending "])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment pending"])}
      },
      "sales": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually Confirmed"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually Confirmed Payments"])}
      }
    }
  },
  "classroom": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classrooms"])},
    "classroom_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom list"])},
    "code_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pending amount"])},
    "code_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
    "add_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a class"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "filter_option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class list"])},
    "filter_option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class successfully deleted"])},
    "filter_option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
    "add_class_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create classrooms and define the expected costs for each one. Parents will receive alerts on the Eska application and will be able to pay"])},
    "button_new_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New class"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment applied. click on a class to set up payments and add students"])},
    "alert_list_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No class created"])},
    "p_list_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to create classes, enrol pupils in them, generate payments for each class and allow parents to receive alerts via the mobile application"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this class?"])},
    "modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting this class will result in the loss of certain data associated with this class but this operation is not retrospective, payments already sent or settled will not be impacted."])}
  },
  "classdetails": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "no_group_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment group created"])},
    "no_group_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please click below to create payment groups."])},
    "select_groupe_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please apply a group to the class in order to apply its payment bands to the pupils in the class."])},
    "no_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No instalment configured."])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "no_other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other payment created."])},
    "modal_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now add students to the class and change the status of their payments."])},
    "modal_success_groupe_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group was successfully applied to the class."])},
    "modal_apply_groupe_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to apply this group to this class."])},
    "payment_no_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments approved manually."])},
    "modal_noinstallment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to apply group to class."])},
    "modal_noinstallment_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't apply this group to a class because it doesn't contain a payment range."])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "receipt_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts sent by parents will appear here."])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payment"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricul"])},
    "PendingPayment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payment"])}
    },
    "PaymentOverdue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue payment"])}
    },
    "class_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot add students"])},
    "class_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please apply a group to the class in order to be able to add studentss"])}
  },
  "modal_pending": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply a status to this installment for selected students ?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment status of the chosen installment, for all selected students, will be defined by one of the choices below. Please check the appropriate one and confirm."])},
    "status_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payment"])},
    "status_pending_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This installment will be marked as unpaid for the selected students, payment requests will be sent to parents on Eska mobile app and they will receive a notification."])},
    "status_settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment settled"])},
    "status_settled_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This option indicates that you have already received the money from the selected students for this installment, the payment will appear as settled on your interface, as well as on Eska app. Check carefully before confirming."])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an option before confirming. "])}
  },
  "modal_pending_success": {
    "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification sent on Eska"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status modified successfully"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the Send button below to inform parents of affected students that a payment is pending."])}
  },
  "modal_settled_success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status modified successfully"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If no receipt is received, a message will be sent to the various managers to inform them of this modification."])}
  },
  "studentlist": {
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "no_student_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No students added"])},
    "no_student_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add students to the class in order to be able to apply the payment bands from the configuration applied to the class."])},
    "table_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select students to change their payment status."])},
    "table_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "table_matricul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "no_statut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No status"])},
    "table_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "schooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schooling"])},
    "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
    "table_current_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
    "table_statut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settled"])},
    "select_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select status"])},
    "select_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the installment"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment"])},
    "title_modal_no_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No slice selected."])},
    "text_modal_no_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the modal and select a slice from the list of slices before applying."])},
    "title_modal_apply_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to apply this band to the class?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "modal_title_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slice successfully applied to students."])},
    "modal_text_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now change the status of students by selecting the appropriate status."])},
    "title_modal_no_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No status selected."])},
    "text_modal_no_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the modal and select a status from the status list before applying."])},
    "title_modal_status_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to change the status of this payment?"])},
    "text_modal_status_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et quoniam apud eos ut in capite mundi morborum acerbitates celsius dominantur, ad quos vel sedandos omnis professio medendi torpescit, excogitatum est adminiculum sospitale nequi amicum perferentem similia videat, additumque est cautionibus paucis remedium aliud satis validum, ut famulos percontatum missos quem ad modum valeant noti."])},
    "text_modal_settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without invoice confirmation, a message will be sent to the various managers to inform them of this change."])},
    "title_modal_status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status has been modified successfully"])},
    "text_modal_status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the button sent below to inform the parents of the pupils concerned that an invoice is pending."])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payment"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "no_statut_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No status applied"])},
    "pending_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payments"])},
    "settled_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settled payments"])},
    "overdue_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue payments"])},
    "select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to edit the status of this slice"])}
  },
  "payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class group"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total amount"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define the payment tranches for this group"])},
    "total_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installments"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group successfully deleted"])},
    "button_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "no_payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No configuration has been created"])},
    "no_payment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment configuration offers you the possibility of dividing your payments into several instalments, according to each class."])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this group?"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is not retroactive, payments already sent or settled will not be impacted. You will need to redefine the applicable fees for the classrooms to which the group was applied. "])}
  },
  "newpayment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create payment configuration"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A group is a group of classes that pay the same amount. <br/> Ex: 6ème,2ndeA,4ème. Choose a level and a speciality, then save."])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "title_icon_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create installment"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the group"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group successfully created"])},
    "modal_button_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create installments   "])},
    "modal_button_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add  group"])},
    "modal_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now create installments for this group, or create another group."])},
    "no_payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment configuration created"])},
    "no_payment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment configuration offers you the possibility of dividing your payments into several instalments, according to each class"])}
  },
  "updatepayment": {
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify group"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to modify this group?"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group modified successfully"])}
  },
  "other_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payments"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
    "period_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the list of periods"])},
    "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring"])},
    "title_icon_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete payment"])},
    "title_no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other payment"])},
    "modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment type"])},
    "modal_add_card1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment made in a single installment."])},
    "modal_add_card2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment that repeats regularly, on a periodic basis."])},
    "message_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment successfully deleted."])}
  },
  "single_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Payment"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Single Payment"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "page_modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the payment"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment created successfully"])},
    "message_modify_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment modified successfully"])},
    "message_modify_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error modifying the payment"])},
    "modal_modify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to modify this payment?"])},
    "modal_modify_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation is not retroactive; payments that have already been sent or settled will not be affected. You will need to redefine the applicable fees for the classes to which the payment is linked."])}
  },
  "recurring_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring Payment"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Recurring Payment"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "error_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])},
    "error_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month is required"])},
    "error_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day is required"])},
    "add_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Period"])},
    "delete_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Period"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment created successfully"])},
    "period_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, you can define the different applicable payment periods, such as monthly, quarterly or annual. This will help us better understand your payment frequency needs."])}
  },
  "installment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment instalments"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "title_payement_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now allocate instalments to each pupil in this class"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "modal_modify_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the installment"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "amount_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter  amount"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The instalment configuration is complete, you can now view your payments"])},
    "no_installment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment instalments"])},
    "no_installment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instalments correspond to defined periods for the payment of tuition fees"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "title_icon_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the instalment"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the instalment"])},
    "modal_modify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to modify this installment?"])},
    "modal_modify_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is not retroactive, payments already sent or settled will not be impacted. You will have to redefine the applicable fees for the classrooms to which the group was linked."])},
    "add_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add installment"])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this slice?"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation is not retroactive. Payments already sent or paid will not be affected. You will need to redefine the applicable fees for the following classes: "])},
    "message_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment deleted successfully"])},
    "message_modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment modified successfully"])}
  },
  "params": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use these impressive forms to log in or create a new account in your project for free."])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration parameters"])},
    "training_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type formation "])},
    "education_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education system"])},
    "education_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational level"])},
    "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "neighbourhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neighbourhood"])},
    "school.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name school"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations incorrects ?"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the platform "])}
  },
  "paymentpending": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments awaiting confirmation"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "matricul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricul"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details pending"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installment"])},
    "title_modal_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject payment"])},
    "p_modal_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicate the reason for rejecting the payment"])},
    "modal_reject_motif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter reason...."])},
    "title_modal_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to confirm this payment?"])},
    "text_modal_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vide, quantum, inquam, fallare, Torquate. oratio me istius philosophi non offendit; nam et complectitur verbis, quod vult, et dicit plane, quod intellegam; et tamen ego a philosopho, si afferat eloquentiam, non asperner, si non habeat, non admodum flagitem. re mihi non aeque"])}
  },
  "accounts": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of administrators"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "title_icon_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send informations"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "autorisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation"])},
    "no_admint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No administrator added"])},
    "lock_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure you want to revoke this user access?"])},
    "lock_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will no nore be able to access this admin panel."])},
    "unlock_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure you want to grant access to this user?"])},
    "unlock_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will be able to access this admin panel again."])},
    "title_icon_lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block user "])},
    "title_icon_unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock user"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorised"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not authorised"])},
    "no_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page reserved for admins"])},
    "message_lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User locked successfully"])},
    "message_unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User unlocked successfully"])}
  },
  "newaccounts": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New administrator"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an administrator"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number (used on Eska)"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone (695623235)"])},
    "no_eska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is not on Eska."])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added successfully."])}
  },
  "logout": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure you want to logout?"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User logged out successfully."])}
  },
  "students_detail": {
    "modal_no_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to delete the student"])},
    "modal_no_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete this student because they have already made payments."])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the student"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation cannot be reverted. His/Her payment history will be kept."])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricul"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents' telephone numbers"])},
    "phone_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate numbers with commas"])},
    "place_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "sexe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settled"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payment"])}
  },
  "new_student": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New student"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new student"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricul"])},
    "number_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number or generate it automatically"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
    "gendered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gendered"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents  numbers"])},
    "phone_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After adding the number, please press the space bar on the keyboard to save"])},
    "place_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "image_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click in the image frame to add an image"])},
    "placeholder_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number(s)"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student added successfully"])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New student"])},
    "old_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old students"])}
  },
  "import_student": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import students from a csv file"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the following csv file template to fill in the students in order to add them to the platform (You can fill them in an excel file by following this template and saving it at the end in csv format)."])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV file"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to view "])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "generate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If student does not have a registration number, press Generate to create a new."])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number of students to generate"])},
    "old_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former students"])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New students"])},
    "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the number of registration numbers to generate."])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file has been successfully generated."])},
    "title_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate the registration number"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload the file"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate the matriculs?"])},
    "text_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A template file will be pre-filled with generated matriculs."])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate and download"])},
    "btn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, thank you!"])},
    "text_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the following button to download the file"])},
    "title_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to download."])},
    "top_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to fill out a file according to the following template:"])},
    "btn_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the template"])}
  },
  "new_class": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New class"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a class sale"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class number"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students"])},
    "no_speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No speciality"])}
  },
  "update_class": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the class"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a class sale"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class number"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to modify this class?"])},
    "text_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paphius quin etiam et Cornelius senatores, ambo venenorum artibus pravis se polluisse confessi, eodem pronuntiante Maximino sunt"])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class successfully modified"])}
  },
  "receipt_list": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts sent"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricul"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grade"])}
  },
  "payment_pending": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment pending"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricul"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No current payment."])}
  },
  "no_bill_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments approved without invoice confirmation"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricul"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment approved."])}
  },
  "duedate_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue payments"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricul"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment."])}
  },
  "list_class_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved payments grouped by class"])}
  },
  "eska": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General statistics"])}
  },
  "message": {
    "notinternet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are offline."])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invoice"])},
    "requiredfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all fields."])},
    "user404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the requested page could not be found."])}
  },
  "title": {
    "page_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Pending"])},
    "page_noreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments approved without receipts"])},
    "page_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments overdue"])}
  },
  "transaction": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction History"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See All"])},
    "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "id_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "all_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All transaction"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To day"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yesterday"])},
    "last_28_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 28 day"])},
    "since_the_1st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the first day"])},
    "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 days"])},
    "all_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All status"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
    "no_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transaction"])},
    "no_transaction_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be able to track payments here in real time"])}
  },
  "transfer": {
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Transfer"])},
    "info_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your collection will be transferred to you as soon as the amount reaches 500,000 FCFA"])},
    "info_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer fees: 4,770 including tax"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "no_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transfer"])}
  }
}