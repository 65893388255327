<template>


  <div class="container-fluid p-0">
    <div class="internet">


      <div class="card px-0 mt-5">
        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
          <div>
            <span class="mt-4">
              <Spinner></Spinner>
            </span>
          </div>
        </spinner>
        <div class="card-body px-0" v-else-if="members.length > 0">
          <div class="row">
            <div class="col-md-6 px-4">

            </div>
            <div class="col-md-6 text-end" style="padding-right: 30px">


              <RouterLink to="/new-account" v-if="members.length > 0">
                <argon-button class="py-2" color="success" size="sm" variant="outline"><i class="fas fa-plus me-2"></i>
                  {{ $t("button.add") }}
                </argon-button>
              </RouterLink>

            </div>
          </div>
          <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
            <div>
              <span class="mt-4">
                <Spinner></Spinner>
              </span>
            </div>
          </spinner>

          <table class="table px-0 mt-6 mb-6" v-else-if="getmenbernostaff">
            <thead>
              <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                <th scope="col" class="border-0">{{ $t("accounts.name") }}</th>

                <th scope="col" class="text-start border-0 px-0">
                  {{ $t("accounts.phone") }}
                </th>
                <th scope="col" class="text-start border-0 px-0" style="transform: translateX(-30px)">
                  {{ $t("accounts.role") }}
                </th>
                <th scope="col" class="text-start border-0 px-0" style="transform: translateX(-100px)">
                  {{ $t("accounts.autorisation") }}
                </th>
                <th scope="col" class="text-end border-0" style="padding-right: 30px">
                  {{ $t("accounts.gender") }}
                </th>
                <!--
              <th scope="col">Status</th>
              -->
              </tr>
            </thead>
            <tbody v-if="loading">
              <div class="pt-6" style="width: 150%; height: 250px; margin-left: 100%">
                <Spinner></Spinner>
              </div>
            </tbody>
            <tbody>
              <tr v-for="list in members" :key="list.id" style="
                  position: relative;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                ">
                <td scope="row" class="px-4 border-0"> <span class="content-td">{{ list.last_name }} {{ list.first_name
                    }}</span> </td>

                <td class="px-0 border-0"> <span class="content-td">{{ list.username }}</span></td>
                <td class="border-0" style="transform: translateX(-54px);">
                  <div class="dropdown pt-1 " style=" height:50px;">
                    <button class="btn dropdown-toggle" type="button" :id="'dropdownMenuButton' + list.id"
                      data-bs-toggle="dropdown" aria-expanded="false" style="z-index: 1;">
                      {{ list.is_staff ? 'Administrateur' : $t("accounts.user") }}
                    </button>
                    <ul class="dropdown-menu pb-0" :aria-labelledby="'dropdownMenuButton' + list.id"
                      style="position: absolute; background-color: white; z-index: 1000;  top: 100%; left: 0;">
                      <li>
                        <a class="dropdown-item" href="#" @click="openmodal(list.id)" data-bs-toggle="modal"
                          :data-bs-target="list.is_staff ? '#staticBackdrop23' : '#staticBackdrop22'"
                          :title="'Cliquez pour modifier son rôle'">
                          {{ list.is_staff ? $t("accounts.user") : 'Administrateur' }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>

                <td class="border-0" style="transform: translateX(-90px)">

                  <span class="content-td" v-if="list.is_active == true">{{ $t("accounts.lock") }}</span>
                  <span class="content-td" v-if="list.is_active == false">{{ $t("accounts.unlock") }}</span>

                </td>

                <!--
              <td class="px-4">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    unchecked
                    title="Unlocked/Locked"
                  />
                </div>
              </td>
            -->

                <td class="text-end px-4 position-relative border-0">
                  <span class="amount" v-if="list.gender === 'M'">{{
                    $t("accounts.M")
                    }}</span>
                  <span class="amount" v-if="list.gender === 'F'">{{
                    $t("accounts.F")
                    }}</span>
                  <div class="action position-absolute top-0 mx-4">
                    <!---
                  <router-link
                    :to="{
                      name: 'detail-config',
                      params: { id: list.id },
                    }"
                  >
                    <button
                      class="btn btn-link text-secondary mb-0 text-xl"
                      title="Créer des tranches"
                    >
                      <i class="fa fa-plus text-success" aria-hidden="true"></i>
                    </button>
                  </router-link>
                --->
                    <button v-if="list.is_active == true" class="btn btn-link text-secondary mb-0"
                      title="Bloquer l'utilisateur" data-bs-toggle="modal" data-bs-target="#staticBackdrop20"
                      @click="openmodal(list.id)" style="
                      transform: translateX(-50px);
                      margin-top: 7px;
                      
                    ">

                      <i style="font-size: 18px" class="bi bi-unlock-fill text-success" aria-hidden="true"></i>
                    </button>

                    <button v-if="list.is_active == false" class="btn btn-link text-secondary mb-0"
                      title="Débloquer l'utilisateur" data-bs-toggle="modal" data-bs-target="#staticBackdrop25"
                      @click="openmodal(list.id)" style="
                      transform: translateX(-50px);
                      margin-top: 7px;
                      
                    ">

                      <i style="font-size: 18px" class="bi bi-lock-fill text-success" aria-hidden="true"></i>
                    </button>

                    <button class="btn btn-link text-secondary mb-0" :title="$t('accounts.title_icon_send')"
                      @click="memberinfo(list.username)" style="transform: translateX(-77px); margin-top: 7px">
                      <i style="font-size: 18px" class="fa fa-paper-plane text-success" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>

              <tr>

              </tr>
            </tbody>
          </table>

          <div class="else text-center pb-5" v-else>
            <div class="container">
              <div class="text mt-3">
                <i class="bi bi-exclamation-triangle-fill fs-1 text-warning" style="transform: translateY(50px)"></i>
                <h5 class="mt-4">{{ $t("accounts.no_admin") }}</h5>

                <p class="mt-5">
                  <router-link to="/new-account" class="btn w-20 fs-6 text-center bg-gradient-success"
                    style="width: 200px; z-index: 2">
                    <span class="fa fa-plus-circle m-2"></span>
                    {{ $t("button.add") }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

  <!--========================= modal bloquer =================================== -->

  <div class="modal fade" id="staticBackdrop20" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
            <h5 class="modal-title" id="staticBackdropLabel20"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6>{{ $t("accounts.lock_modal_title") }}</h6>

            <p class="text-justify  px-6" style="margin: 50px auto; width: max-content">
              {{ $t("accounts.lock_modal_text") }}
            </p>
          </div>
          <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
            <argon-button type="button" class="btn bg-transparent border " data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px">
              {{ $t("button.cancel") }}
            </argon-button>

            <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger"
              variant="gradient" type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px"
              @click="confirmBlockUser(studentToDeleteId)">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!--========================= modal débloqué =================================== -->

  <div class="modal fade" id="staticBackdrop25" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
            <h5 class="modal-title" id="staticBackdropLabel20"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6>{{ $t("accounts.unlock_modal_title") }}</h6>

            <p class="text-justify px-6" style="margin: 50px auto; width: max-content">
              {{ $t("accounts.unlock_modal_text") }}
            </p>
          </div>
          <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
            <argon-button type="button" class="btn bg-transparent border" data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px">
              {{ $t("button.cancel") }}
            </argon-button>

            <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger"
              variant="gradient" type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px"
              @click="confirmBlockUser1(studentToDeleteId)">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--========================= modal modifier le rôle (admin)  =================================== -->

  <div class="modal fade" id="staticBackdrop22" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
            <h5 class="modal-title" id="staticBackdropLabel20"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6>Êtes-vous sûr de vouloir modifier le rôle de cet utilisateur?</h6>

            <p class="text-justify  px-6" style="margin: 50px auto; width: max-content">
              L’utilisateur aura accès aux informations reservées aux administrateurs.
            </p>
          </div>
          <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
            <argon-button type="button" class="btn bg-transparent border " data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px">
              {{ $t("button.cancel") }}
            </argon-button>

            <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger"
              variant="gradient" type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px"
              @click="user_admin(studentToDeleteId)">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--========================= modal modifier le rôle (user)  =================================== -->

  <div class="modal fade" id="staticBackdrop23" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
            <h5 class="modal-title" id="staticBackdropLabel20"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6>Êtes-vous sûr de vouloir modifier le rôle de cet utilisateur?</h6>

            <p class="text-justify  px-6" style="margin: 50px auto; width: max-content">
              L’utilisateur n'aura plus accès aux informations reservées aux administrateurs.
            </p>
          </div>
          <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
            <argon-button type="button" class="btn bg-transparent border " data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px">
              {{ $t("button.cancel") }}
            </argon-button>

            <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger"
              variant="gradient" type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px"
              @click="user_user(studentToDeleteId)">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Spinner from "../components/Spinner.vue";
import ArgonButton from "@/components/ArgonButton";

import { getMembers, memberinfo, deletemember, updateMemberStatus } from "@/utils/services";
import { ref, onMounted } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "projects-table",
  data() {
    return {
      username: "",
      admin: "",
      studentToDeleteId: null,
      showConfirmation: false,




    };
  },

  components: { Spinner, ArgonButton },

  setup() {
    const members = ref([]);
    const loading = ref(false);

    const fetchMembers = async () => {
      try {
        loading.value = true;
        const result = await getMembers();
        if (!navigator.onLine) {
          this.internet = false; // Pas de connexion Internet
          this.no_internet = true; // Afficher le contenu pour pas de connexion Internet
          alert(
            "Vous n'êtes pas connecté à Internet. Veuillez vérifier votre connexion et réessayer."
          );
          return;
        }

        if (result.status === 200) {
          members.value = result.data;
          this.internet = true;
          this.no_internet = false;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchMembers();
    });

    return {
      members,
      loading


    };
  },

  methods: {

    openmodal(studentId) {
      this.studentToDeleteId = studentId;

    },


    async confirmBlockUser(studentId) {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        const updatedMember = await updateMemberStatus(studentId, { is_active: false });


        if (updatedMember) {
          this.showConfirmation = true;
          const message = this.$t("accounts.message_lock");
          toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);

        } else {

          console.error('Erreur lors du blocage du membre:', updatedMember.error);
        }
      } catch (error) {

        console.error('Erreur inattendue lors du blocage du membre:', error);
      } finally {
        this.loading = false;
      }
    },


    async user_admin(studentId) {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        const updatedMember = await updateMemberStatus(studentId, { is_staff: true });


        if (updatedMember) {
          this.showConfirmation = true;
          const message = "Rôle modifié avec succès";
          toast.success(message, {
            autoClose: 3000,
          });


          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);


        } else {

          console.error('Erreur lors du blocage du membre:', updatedMember.error);
        }
      } catch (error) {

        console.error('Erreur inattendue lors du blocage du membre:', error);
      } finally {
        this.loading = false;
      }
    },


    async user_user(studentId) {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        const updatedMember = await updateMemberStatus(studentId, { is_staff: false });


        if (updatedMember) {
          this.showConfirmation = true;
          const message = "Rôle modifié avec succès";
          toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);

        } else {

          console.error('Erreur lors du blocage du membre:', updatedMember.error);
        }
      } catch (error) {

        console.error('Erreur inattendue lors du blocage du membre:', error);
      } finally {
        this.loading = false;
      }
    },


    async confirmBlockUser1(studentId) {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        // Mettre à jour la valeur is_active en false
        const updatedMember = await updateMemberStatus(studentId, { is_active: true });

        // Vérifier si la mise à jour a réussi
        if (updatedMember) {
          this.showConfirmation = true;
          const message = this.$t("accounts.message_unlock");
          toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);
        } else {
          // Gérer les cas d'erreur ou afficher un message d'échec si nécessaire
          console.error('Erreur lors du blocage du membre:', updatedMember.error);
        }
      } catch (error) {
        // Capturer les erreurs imprévues et les afficher dans la console
        console.error('Erreur inattendue lors du blocage du membre:', error);
      } finally {
        this.loading = false;
      }
    },




    confirmDelete(studentId) {
      this.studentToDeleteIdToDeleteId = studentId;
    },

    internets() {
      this.internet = false;
      this.no_internet = true;
    },
    getmenbernostaff() {
      return this.members.filter((members) => members.is_staff === false);
    },

    async memberinfo(username) {
      try {
        this.loading = true;
        this.button = "disabled";

        let data = {
          username,
          school_name: localStorage.getItem("name"),
        };

        console.log(data);

        if (!username) {
          const message = this.$t("message.requiredfile");
          toast.info(message, {
            autoClose: 3000,
          });
          return;
        }

        if (username === "" || username === null) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await memberinfo(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 200) {
            const message =
              "Les informations de connexion ont été envoyées avec succès.";
            this.message = toast.success(message, {
              autoClose: 3000,
            });
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
      }
    },

    async deleteStudent(studentId) {
      try {
        if (this.loading) {
          return;
        }

        this.loading = true;

        // Supprimez la configuration
        const deleted = await deletemember(studentId);

        if (deleted) {
          this.showConfirmation = true;
          const message = "Utilisateur supprimé avec succès";
          toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);
        }
      } catch (error) {
        console.error("Error while deleting user:", error);
      } finally {
        this.loading = false;
      }
    },

  },
};
</script>

<style>
/*=======css table ====*/

.card .table thead tr th {
  width: 30%;
}

.card .table tbody tr td .amount {
  position: absolute;
  
  left: 0px;
  top:25px;
}

.card .table tbody tr:hover td .amount {
  filter: blur(2px);

}

.card .table tbody tr {
  transition: 0.7s;
  cursor: pointer;
}

.card .table tbody tr {
  position: relative;
}

.card .table tbody tr td .content-td {
  position: absolute;
  top: 25px;


}

.action {
  display: none;
}

.card .table tbody tr:hover .action {
  display: block;
}



.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/*=======css table ====*/
</style>
