<template>
      
 
    <div class="main-transaction">
        
        <!-- Modal -->
        <div class="modal fade" id="exampleModal60" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="card w-60 mx-auto">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Choisir une période</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3">
                                <label for="startDate" class="form-label">Date de début</label>
                                <input style="height:50px" type="date" class="form-control" id="startDate"
                                    v-model="startDate" :max="maxDate" :min="minDate">
                            </div>
                            <div class="mb-3">
                                <label for="endDate" class="form-label">Date de fin</label>
                                <input style="height:50px" type="date" class="form-control" id="endDate"
                                    v-model="endDate" :max="maxDate" :min="minDate">
                            </div>
                            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
                        </div>
                        <div class="modal-footer">
                            <button style="border:1px solid rgba(255, 255, 255, 0.4); color:white;" type="button"
                                class="btn bg-transparent" data-bs-dismiss="modal">Fermer</button>
                            <button data-bs-dismiss="modal" type="button" class="btn btn-success"
                                @click="applyCustomDateFilter">Appliquer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="container-fluid">
            <div class="d-flex" style="margin-top: 80px;">
                <div class=" " style="margin-top: 30px;">
                    <label for="" style="transform: translateY(-30px)"> Opérateur </label>
                    <div class="dropdown" id="dropdown-status">
                        <button class="btn dropdown-toggle ps-0 text-light fw-bold" type="button" data-bs-toggle="dropdown"
                            style="transform: translateY(-30px)">
                            {{ selectedOperatorText }}
                        </button>
                        <ul class="dropdown-menu">
                            <li @click="setOperator('', 'Tous les opérateurs')">Tous les opérateurs</li>
                            <li @click="setOperator('mtn-momo', 'mtn-momo')">mtn-momo</li>
                            <li @click="setOperator('bicec', 'bicec')">bicec</li>
                            <li @click="setOperator('orange-money', 'orange-money')">orange-money</li>
                        </ul>
                    </div>
                </div>
                <div class="mx-4" style="margin-top: 30px;">
                    <label for="" style="transform: translateY(-30px)">{{ $t("transaction.status") }}</label>
                    <div class="dropdown" id="dropdown-status">
                        <button class="btn dropdown-toggle text-light fw-bold ps-0" type="button" data-bs-toggle="dropdown"
                            style="transform: translateY(-30px)">
                            {{ selectedStatusText }}
                        </button>
                        <ul class="dropdown-menu">
                            <li @click="setStatus('', $t('transaction.all_status'))">{{ $t("transaction.all_status") }}
                            </li>
                            <li @click="setStatus('Success', $t('transaction.successful'))">{{
                                $t("transaction.successful") }}</li>
                            <li @click="setStatus('Pending', $t('transaction.running'))">{{ $t("transaction.running") }}
                            </li>
                            <li @click="setStatus('Failed', 'Echouées')">{{ $t("transaction.failed") }}</li>

                            <!--
                            <li @click="setStatus('Dropped', 'Abandonnées')">Abandonnées</li>
                            <li @click="setStatus('APIError', 'Erreur API')">Erreur API</li>
                            <li @click="setStatus('SimulatedFail', 'Simulation d\'échec')">Simulation d'échec</li>
                            -->
                            <li @click="setStatus('Declined', 'Refusées')">{{ $t("transaction.refused") }}</li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-3" style="margin-top: 30px;">
                    <label for="" style="transform: translateY(-30px)"> {{ $t("transaction.period") }}</label>
                    <div class="dropdown " id="dropdown2">
                        <button class="btn  dropdown-toggle text-light fw-bold" type="button" data-bs-toggle="dropdown"
                            style="transform: translateY(-30px)">
                            {{ $t("transaction.today") }}
                        </button>
                        <ul class="dropdown-menu">
                            
                            <li @click="setPeriod('today', $t('transaction.today'))">{{ $t("transaction.today") }}</li>
                            <li @click="setPeriod('yesterday', $t('transaction.yesterday'))">{{
                                $t("transaction.yesterday") }}</li>
                            <li @click="setPeriod('last_7_days', $t('transaction.last_7_days'))">{{
                                $t("transaction.last_7_days") }}</li>
                            <li @click="setPeriod('last_28_days', $t('transaction.last_28_days'))">{{
                                $t("transaction.last_28_days") }}</li>
                            <li data-bs-toggle="modal" data-bs-target="#exampleModal60"> Autre</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!--Card -box -->
        <div class="container-fluid mt-5">
            <div class="row " id="card">
                <div class="col-md-3">
                    <div class="card1 bg-gradient-info">
                        <div class="top">
                            <span> Total : </span>
                        </div>

                        <div class="botoom text-end mt-3">
                            <spinner class="text-center " v-if="loading">
                                <div>
                                    <span class="mt-4">
                                        <Spinner></Spinner>
                                    </span>
                                </div>
                            </spinner>

                            <span class="price "
                                v-else-if="transactionSummary.total && transactionSummary.total.count">{{
                                    transactionSummary.total.count
                                }}
                            </span>

                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card1 bg-gradient-success">
                        <div class="top">
                            <span> {{ $t("transaction.successful") }} : {{ transactionSummary.successful.count }}</span>
                        </div>
                        <div class="botoom text-end mt-3">
                            <spinner class="text-center " v-if="loading">
                                <div>
                                    <span class="mt-4">
                                        <Spinner></Spinner>
                                    </span>
                                </div>
                            </spinner>

                            <span class="price" v-else-if="transactionSummary"> XAF {{
                                Number(transactionSummary.successful.amount).toLocaleString() }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card1 bg-gradient-warning">
                        <div class="top">
                            <span> {{ $t("transaction.running") }}: {{ transactionSummary.running.count }}</span>
                        </div>
                        <div class="botoom text-end mt-3">
                            <spinner class="text-center " v-if="loading">
                                <div>
                                    <span class="mt-4">
                                        <Spinner></Spinner>
                                    </span>
                                </div>
                            </spinner>

                            <span class="price" v-else-if="transactionSummary"> XAF {{
                                Number(transactionSummary.running.amount).toLocaleString() }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card1 bg-gradient-danger">
                        <div class="top">
                            <span> Echouées: {{ transactionSummary.failed.count }}</span>
                        </div>
                        <div class="botoom text-end mt-3">
                            <spinner class="text-center " v-if="loading">
                                <div>
                                    <span class="mt-4">
                                        <Spinner></Spinner>
                                    </span>
                                </div>
                            </spinner>

                            <span class="price" v-else-if="transactionSummary"> XAF {{
                                Number(transactionSummary.failed.amount).toLocaleString() }}
                            </span>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div class="container-fluid">
            <div class="card px-0 mt-7">
                <div class="card-body px-0">

                    <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                        <div>
                            <span class="mt-4">
                                <Spinner></Spinner>
                            </span>
                        </div>
                    </spinner>


                    <table class="table px-0 mt-6 mb-6" v-else-if="filteredTable.length > 0">
                        <thead>
                            <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                                <th scope="col" class="border-0 w-20">
                                    {{ $t("transaction.operator") }}
                                </th>
                                <th class="border-0 text-end w-20 pe-0" style="text-decoration: none">
                                    {{ $t("transaction.amount") }} (XAF)
                                </th>
                                <th class="border-0 w-20 text-end pe-0" style="text-decoration: none">
                                    Id Transaction
                                </th>
                                <th class="border-0 w-20 text-end pe-0">
                                    {{ $t("transaction.id_user") }}
                                </th>
                                <th class="border-0 w-20 text-end pe-0">
                                    {{ $t("transaction.date") }}
                                </th>
                                <th class="text-end w-20 border-0">
                                    {{ $t("transaction.status") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="list in paginatedfilteredTable" :key="list.id"
                                style="position: relative; border-bottom: 1px solid rgba(255, 255, 255, 0.1);">
                                <td scope="row" class="px-4 border-0" title="Cliquez pour modifier le groupe">
                                    {{ list.operator }}
                                </td>
                                <td class="border-0  text-end">
                                    <span class="amount">{{ Number(list.amount).toLocaleString() }}</span>
                                </td>
                                <td class="border-0 pe-0 text-end">

                                    ***<span class="amount"> {{ list.processor_tx_id }}</span>
                                </td>
                                <td class="text-end border-0 pe-0 position-relative">
                                    {{ list.username }}
                                </td>
                                <td class="ps-0 text-end border-0 pe-0 position-relative">
                                    {{ formatDate(list.date) }}
                                </td>
                                <td class="text-end  border-0 position-relative pe-4">
                                    <div v-if="list.status === 'FAILED'" class="  text-end" style="width: 130px">
                                        échouée
                                    </div>
                                    <div v-if="list.status === 'APIError'" title="Erreur d'API" class="   text-end"
                                        style="width: 130px">
                                        Erreur
                                    </div>
                                    <div v-if="list.status === 'Success'" class=" text-end" style="width: 130px">
                                        Réussie
                                    </div>
                                    <div v-if="list.status === 'Pending'" class="  text-end" style="width: 130px">
                                        En cours
                                    </div>
                                    <div v-if="list.status === 'Dropped'" class=" text-end" style="width: 130px">
                                        Abandonnées
                                    </div>
                                    <div v-if="list.status === 'SimulatedFail'" class=" text-end" style="width: 130px">
                                        Simulation d'echec
                                    </div>
                                    <div v-if="list.status === 'Declined'" class="text-end" style="width: 130px">
                                        Refusées
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>


                    </table>


                    <div class="w-100  justify-content-center text-center" v-else
                        style="height: auto; padding: 70px 0;">
                        <div>
                            <img style="width:300px" class="image" src="@/assets/img/transaction.png" alt="" />
                            <h5 style="transform: translateX(40px)" class="mt-4">{{ $t("transaction.no_transaction") }}
                            </h5>

                        </div>
                        <p style="width:max-content" class="mx-auto text-center">
                            {{ $t("transaction.no_transaction_text") }} </p>

                    </div>

                    <div class="">
                        <nav aria-label="..." v-if="filteredTable.length > 0">
                            <ul class="pagination">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <button class="page-link" @click="prevPage" :disabled="currentPage === 1"
                                        style="background: none; color: white">
                                        <i class="bi bi-chevron-left"></i>
                                    </button>
                                </li>
                                <li v-for="page in totalPages" :key="page" class="page-item"
                                    :class="{ active: currentPage === page }">
                                    <button class="page-link fw-bold" style="background: none"
                                        @click="() => setCurrentPage(page)">
                                        {{ page }}
                                    </button>
                                </li>

                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <button class="page-link" @click="nextPage" :disabled="currentPage === totalPages"
                                        style="
                    background: none;
                    color: white;
                  ">
                                        <i class="bi bi-chevron-right"></i>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getconnection, getTransactions } from "@/utils/services";
import Spinner from "../components/Spinner.vue";

// Fonction pour formater la date d'aujourd'hui
function getFormattedDate() {
  const today = new Date();

  // Formater la date au format YYYY-MM-DD
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
  const day = String(today.getDate()).padStart(2, '0');

  // Début de la journée
  const starting_on = `${year}-${month}-${day} 00:00:00`;

  // Fin de la journée
  const ending_on = `${year}-${month}-${day} 23:59:59`;

  return { starting_on, ending_on };
}

export default {
    name: "historique-Transaction",
    components: { Spinner },
    data() {
        return {
            isOffline: !navigator.onLine,
            searchName: "",
            searchStatus: "",
            no: "",
            schoolId: '',
            schoolInfo: {},
            xpay_connection_id: "667c4d415b6e1ee3dcd018e2",
            period: 'today',
            status: '',
            selectedOperator: '',
            selectedOperatorText: 'Tous les opérateurs',
            selectedStatus: '',
            selectedStatusText: this.$t("transaction.all_status"),
            selectedPeriodText: this.$t("transaction.today"),
            table: [],
            transactionSummary: {
                total: { count: 0, amount: 0 },
                successful: { count: 0, amount: 0 },
                running: { count: 0, amount: 0 },
                failed: { count: 0, amount: 0 },
                dropped: { count: 0, amount: 0 },
            },
            loading: true,
            periods: [],
            currentPage: 1,
            itemsPerPage: 50,
            startDate: '',
            endDate: '',
            maxDate: '',
            minDate: '',
        };
    },
    computed: {
        filteredTable() {
            return this.table.filter((transaction) => {
                const matchesName = transaction.operator.toLowerCase().includes(this.searchName.toLowerCase());
                const matchesStatus = this.searchStatus ? transaction.status === this.searchStatus : true;
                const matchesDate = this.filterByDate(transaction.date);
                const matchesOperator = this.selectedOperator ? transaction.operator === this.selectedOperator : true;
                return matchesName && matchesStatus && matchesDate && matchesOperator;
            });
        },
        totalAmount() {
            return this.filteredTable.reduce(
                (total, item) => total + parseFloat(item.amount_paid || 0),
                0
            );
        },
        totalPages() {
            return Math.ceil(this.filteredTable.length / this.itemsPerPage);
        },
        paginatedfilteredTable() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.filteredTable.slice(startIndex, endIndex);
        },
    },
    methods: {
        // Ajouter la fonction pour formater la date ici si nécessaire
        getFormattedDate,

        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        setCurrentPage(page) {
            this.currentPage = page;
        },

        async fetchgetconnexion() {
            let data = {
                school_id: this.schoolId
            }

            try {
                let response = await getconnection(data);
                if (response.length > 0) {
                    //gggggggg
                } else {
                    console.error("Invalid response data:", response);
                    this.failedMessage = "Les informations récupérées sont invalides.";
                }
            } catch (error) {
                console.error("Erreur:", error);
                this.failedMessage = "Une erreur s'est produite lors de la récupération des informations.";
            }
        },

        async applyCustomDateFilter() {
            this.errorMessage = ''; // Clear any previous error message
            if (this.startDate && this.endDate) {
                const start = new Date(this.startDate);
                const end = new Date(this.endDate);
                if (start > end) {
                    this.errorMessage = "La date de début ne peut pas être supérieure à la date de fin.";
                    return; // Exit if there's an error
                }

                const xpay_connection_id = this.xpay_connection_id;
                const period = this.no;
                const starting_on = this.startDate;
                const ending_on = this.endDate;
                await this.fetchTransactions(xpay_connection_id, starting_on, ending_on, period);
            }
        },

        async fetchTransactions() {
            // Utilisation des valeurs de l'instance de l'objet
            const xpay_connection_id = this.xpay_connection_id;

            // Utiliser la fonction pour obtenir les dates du jour
            const { starting_on, ending_on } = this.getFormattedDate();

            this.loading = true;
            try {
                let response = await getTransactions(xpay_connection_id, starting_on, ending_on, this.period);

                if (response) {
                    this.table = response.transactions || [];
                    this.transactionSummary = response.sum_transaction || {
                        total: { count: 0, amount: 0 },
                        successful: { count: 0, amount: 0 },
                        running: { count: 0, amount: 0 },
                        failed: { count: 0, amount: 0 },
                        dropped: { count: 0, amount: 0 },
                    };
                } else {
                    console.error("La réponse est vide ou non définie.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des transactions :", error);
                this.failedMessage = "Une erreur s'est produite lors de la récupération des informations.";
            } finally {
                this.loading = false;
            }
        },

        filterByDate(transactionDate) {
            if (!this.startDate && !this.endDate) {
                return true;
            }
            const date = new Date(transactionDate);
            const start = this.startDate ? new Date(this.startDate) : null;
            const end = this.endDate ? new Date(this.endDate) : null;

            if (start && end) {
                return date >= start && date <= end;
            } else if (start) {
                return date >= start;
            } else if (end) {
                return date <= end;
            }
            return true;
        },

        async setPeriod(period, text) {
            this.period = period;
            this.selectedPeriodText = text;

            const { starting_on, ending_on } = this.getFormattedDate();

            await this.fetchTransactions(this.xpay_connection_id, starting_on, ending_on, this.period);
        },

        setOperator(operator, text) {
            this.selectedOperator = operator;
            this.selectedOperatorText = text;
        },

        setStatus(status, text) {
            this.searchStatus = status;
            this.selectedStatusText = text;
        },

        formatDate(dateString) {
            return dateString.split('T')[0];
        }
    },
    mounted() {
        this.schoolId = localStorage.getItem("schoolId");
        this.fetchgetconnexion();
        this.fetchTransactions();

        const today = new Date();
        this.maxDate = today.toISOString().split('T')[0];
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(today.getMonth() - 3);
        this.minDate = threeMonthsAgo.toISOString().split('T')[0];

        window.addEventListener('offline', () => {
            this.isOffline = true;
        });
        window.addEventListener('online', () => {
            this.isOffline = false;
        });
    }
};
</script>



<style>
#form {
    background: none;
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;
}

#dropdown2 .btn {
    height: 45px;
    border: 1px solid rgba(250, 250, 250, 0.6);
    width: 200px;

}

#dropdown2 .dropdown-menu li {
    padding: 7px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    color: white;
}

#dropdown2 .dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}

#dropdown-status .btn {
    height: 45px;
    border: 1px solid rgba(250, 250, 250, 0.6);
    width: 200px;
    background: none;
}

#dropdown-status .dropdown-menu {
    background-color: #293357;
}

#dropdown-status .dropdown-menu li {
    padding: 7px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    color: white;
    cursor: pointer;
}

#dropdown-status .dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}




#card .card1 {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    color: white;
    border-radius: 10px;
    height: 140px;
}

#card .card1 span {
    font-size: 18px;
}

#card .card1 .price {
    font-size: 25px;
    font-weight: bold;
}

.table tbody tr td {
    padding-top: 13px;
    padding-bottom: 13px;
}

#span-table {
    width: 150px;
}

#option1 {
    background-color: transparent;
    color: rgba(250, 250, 250, 0.6);
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;

}

#option1[value="Declined"] {
    background-color: transparent;
    color: rgba(250, 250, 250, 0.6);
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;
}

.pagination {
    transform: translateY(-50px);
    display: flex;
}

.pagination button {
    margin: 0 5px;
    cursor: pointer;
    background-color: #081739;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 20px;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
</style>
