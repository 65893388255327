 
<template>
  <div class="top" style="width: 99%">
    <div class="card mx-3" style="margin-top: 94px">
      <div class="card-body px-0 pt-0 mt-4">
        <div class="row" v-if="classroomReport && classroomReport.pending_payment.students.length > 0">
          <div class="col-md-7" style="padding-left: 35px"></div>
          <div class="col-md-5 text-end" style="padding-right: 30px">
            <h6>
              Total :<span class="opacity-6 mx-2" style="font-size: 18px">XAF
                {{ Number(getTotalPaymentsAmount()).toLocaleString() }}
              </span>
            </h6>
          </div>
        </div>
        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
          <div>
            <span class="mt-4">
              <Spinner></Spinner>
            </span>
          </div>
        </spinner>
        <div class="table-responsive p-0 mt-5 mb-5" v-else-if="classroomReport && classroomReport.pending_payment.students.length > 0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th scope="col" style="text-transform: none"> {{ $t("payment_pending.name") }}</th>
                <th scope="col" style="text-transform: none"> {{ $t("payment_pending.firstname") }} </th>
                <th scope="col"> {{ $t("payment_pending.number") }}</th>
                <th scope="col" > {{ $t("payment_pending.amount") }} </th>
                 
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, index) in paginatedStudents" :key="index">
                <td class="px-4">
                  {{ student.last_name }}  
                </td>
                <td class="px-4">
                   {{ student.first_name }}
                </td>
                <td class="px-4">{{ student.registration_number }}</td>
                <td class="text-end px-5" v-if="student.payments && student.payments.length > 0">
                  <div class="dropdown">
                    <button class="text-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false" style="font-size: 18px; background: none; border: none">
                      <span style="width: 25px; height: 25px; border-radius: 50%; padding-top: 7px;"
                    class="badge badge-sm bg-gradient-warning">
                    {{ student.pendingPaymentsCount }}
                  </span>
                    </button>
                    <ul class="dropdown-menu" id="li" aria-labelledby="dropdownMenuButton1">
                      <li v-for="(payment, index) in student.payments" :key="index">
                        <a v-if="payment.status === 'Pending'" class="dropdown-item" href="#">
                          XAF {{ Number(payment.amount).toLocaleString() }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
                 
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="...">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="prevPage" :disabled="currentPage === 1" style="background: none; color: white">
                  <i class="bi bi-chevron-left"></i>
                </button>
              </li>
              <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
                <button class="page-link fw-bold" style="background: none" @click="setCurrentPage(page)">
                  {{ page }}
                </button>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <button class="page-link" @click="nextPage" :disabled="currentPage === totalPages" style="background: none; color: white">
                  <i class="bi bi-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="else text-center pb-5" v-else>
          <div class="container">
            <div class="text mt-3">
              <img style="transform: translateX(-40px)" class="image" src="@/assets/img/paiementlist.png" alt="" />
              <h5 class="mt-4">{{ $t("payment_pending.no_payment") }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

 
<script>
import apiConfig from "@/config";
import { getclassroomreport } from "@/utils/services";
import Spinner from "../components/Spinner.vue";

export default {
  name: "Pending-List",
  components: { Spinner },
  data() {
    return {
      classroom_id: this.$route.params.classId,
      table: [],
      loading: false,
      settled: apiConfig.SETTLED,
      accepted: apiConfig.ACCEPTED,
      overdue: apiConfig.OVERDUE,
      pending: apiConfig.PENDING,
      paid: apiConfig.PAID,
      currentPage: 1,
      itemsPerPage: 50,
    };
  },

  mounted() {
    this.fetchClassroomReport(this.classroom_id);
  },

  computed: {
    paginatedStudents() {
      if (!this.classroomReport) return [];
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.classroomReport.pending_payment.students.slice(startIndex, endIndex).map(student => {
        return {
          ...student,
          pendingPaymentsCount: student.payments.filter(payment => payment.status === 'Pending').length
        };
      });
    },
    totalPages() {
      if (!this.classroomReport) return 0;
      return Math.ceil(this.classroomReport.pending_payment.students.length / this.itemsPerPage);
    },
  },

  methods: {
    getTotalPaymentsAmount() {
      let totalAmount = 0;
      this.classroomReport.pending_payment.students.forEach(student => {
        student.payments.forEach(payment => {
          totalAmount += payment.amount;
        });
      });
      return totalAmount;
    },
    async fetchClassroomReport(classroom_id) {
      try {
        this.loading = true;
        const response = await getclassroomreport(classroom_id);
        if (response) {
          this.classroomReport = response;
        }
      } catch (error) {
        console.error("Error fetching classroom report:", error);
      } finally {
        this.loading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
  },

  props: {
    Students: {
      type: Array,
      default: () => [],
    },
    configInstallments: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    const paymentConfigApplied = localStorage.getItem("paymentConfigApplied");
    if (paymentConfigApplied === "true") {
      this.showOverlay = false;
    }
    this.pendingStudentsPaymentsList = this.pendingStudentsPayments;
  },
};
</script>

<style scoped>
.main {
  position: relative;
}

.card {
  min-height: 48vh;
}

#avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transition: 300ms ease;
}

#avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
  user-select: none;
  pointer-events: none;
}

#avatar:hover {
  padding: 10px;
  transform: rotate(10deg);
}

.custom-select {
  position: relative;
}

.tabble-buttun {
  height: 40px;
  margin-top: 20px;
}

.tabble-buttun span i {
  font-size: 18px;
  cursor: pointer;
  transform: translateY(10px);
}

.pagination {
  margin-top: 10px;
  display: flex;
  width: max-content;
}

.pagination button {
  margin: 0 5px;
  cursor: pointer;
  background-color: #081739;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 20px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

 

#li li a {
  color: white;
  opacity: 0.6;
  font-size: 16px;
}

#li li a:hover {
  background: none;
}
</style>