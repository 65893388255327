<template>
 

  <div class="container top-0 position-sticky z-index-sticky"></div>
  <div v-if="isOffline" class="" id="alert">
    <div class="container">
      <Noonline />
    </div>
  </div>
  <main class="main-content mt-0" v-else>
    <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg" style="
        background-image: url(&quot;https://cdn.pixabay.com/photo/2015/07/19/10/00/school-work-851328_640.jpg&quot;);
      ">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <spinner class="text-center" v-if="loading" style="height: 200px">
            <div>
              <span class="mt-4">
                <Spinner></Spinner>
              </span>
            </div>
          </spinner>

          <div class="col-lg-5 mt-8 text-center mx-auto" v-else>
            <h3 class="text-white mb-2 mt-3">
              {{ $t("params.params") }}{{ schoolInfo.name }}
            </h3>

            <div class="button mt-4 mb-4">

              <span @click="saveConfig" title="Aller la page d'accueil" class="opacity-6 heart-animation"
                :loading="loading" :active="buttonActive || showConfirmation">
                Continuer
              </span>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-10 col-lg-10 col-md-10 mx-auto">
          <div class="card z-index-0">
            <div class="card-body">
              <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                <div>
                  <span class="mt-4">
                    <Spinner></Spinner>
                  </span>
                </div>
              </spinner>

              <form role="form" v-else>
                <div class="row">
                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.school_name") }}
                    </h6>
                    <p class="mt-4">{{ name }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>

                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.training_type") }}
                    </h6>
                    <p class="mt-4">{{ training_type1 }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>

                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.education_system") }}
                    </h6>

                    <p class="mt-3">{{ education_system1 }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>

                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.education_grade") }}
                    </h6>

                    <p class="mt-3">{{ education_grade1 }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>

                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.country_code") }}
                    </h6>

                    <p class="mt-4">{{ country_code }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>

                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.currency") }}
                    </h6>

                    <p class="mt-4">{{ currency_code }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>

                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.owner") }}
                    </h6>

                    <p class="mt-4">{{ owner }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>

                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.region") }}
                    </h6>

                    <p class="mt-4">{{ region }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>
                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.city") }}
                    </h6>

                    <p class="mt-4">{{ town }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>

                  <div class="mb-3 col-6 px-8">
                    <h6 style="transform: translateY(13px)" class="fw-bold">
                      {{ $t("params.neighbourhood") }}

                    </h6>

                    <p class="mt-4">{{ hood }}</p>
                    <hr class="mt-2 horizontal" />
                  </div>
                </div>
              </form>

              <div class="text text-center mt-4" v-if="welcome">
                <p> {{ $t("params.text1") }}</p>
                <p>
                  {{ $t("params.text2") }}
                  <span><a title="Cliquer pour contacter Eska" class="link fw-bold" target="_blank"
                      href="#">Eska</a></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/widget/PageLayout/Footer.vue";
import { getConfig } from "@/utils/auth";


import "vue3-toastify/dist/index.css";
import { getSchoolinfo } from "@/utils/services";
import Spinner from "../components/Spinner.vue";
import { pullGrades, pullSpecialities, updateConfig } from "@/utils/setup";
import Noonline from ".//components/NoOnline.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "app-config",
  components: {
    AppFooter,
    Spinner,
    Noonline,
  },
  data() {
    return {
      button: "",
      buttonActive: false,
      showConfirmation: false,
      schoolInfo: {},
      isOffline: !navigator.onLine,

      TRAINING_TYPE: [
        {
          code: "General",
          name: "General",
        },
        {
          code: "Professional",
          name: "Professional",
        },
      ],
      EDUCATION_SYSTEM: [
        {
          code: "Bilingual",
          name: "Bilingual",
        },
        {
          code: "French",
          name: "French",
        },
        {
          code: "English",
          name: "English",
        },
      ],
      EDUCATION_GRADE: [
        {
          code: "Secondary",
          name: "Secondary",
        },
        {
          code: "Primary",
          name: "Primary",
        },
        {
          code: "Nursery",
          name: "Nursery",
        },
      ],
      currency_code: "",
      configId: "",
      country_code: "CM",
      training_type1: "",
      education_system1: "",
      education_grade1: "",
      education_system: "",
      school_id: "",
      test: "",
      welcome: null,
      loading: false,
      name: "",
      loacalisation: "",
      region: "",
      town: "",
      hood: "",
      owner: "",



    };
  },

  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
  },



  methods: {
    updateSchoolId(id) {
      this.school_id = id;
      localStorage.setItem("schoolId", id);
    },

    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
      this.checkInternetConnection();
    },


    async checkInternetConnection() {
      try {
        const response = await fetch("https://www.google.com", {
          mode: "no-cors",
        });
        if (response.ok || response.type === 'opaque') {
          this.isOffline = false;
        } else {
          this.isOffline = true;
        }
      } catch (error) {
        this.isOffline = true;
      }
    },


    async getAppConfig() {
      try {
        this.loading = true;
        let data = await getConfig();
        console.log("info  get config", data)
        setTimeout(() => {
          if (!navigator.onLine) {
            alert(
              "Vous n'êtes pas connecté à Internet. Veuillez vérifier votre connexion et réessayer."
            );
          }
        }, 30000);
        this.configId = data[0].id;

        console.log("config id ", this.configId)

        if (!this.training_type1) {
          this.training_type1 = data.training_type;
        }
        if (!this.education_system1) {
          this.education_system1 = data.education_system;
        }
        if (!this.education_grade1) {
          this.education_grade1 = data.education_grade;
        }
        if (!this.school_id) {
          this.school_id = data[0].school_id;
          localStorage.setItem("schoolId", this.school_id);

        }
        if (!this.currency_code) {
          this.currency_code = data[0].currency_code;
        }




        // Récupérez school_id

        // Appelez getSchoolInfo avec school_id
        await this.getSchoolInfo(this.school_id);

        // Prendre en compte les valeurs renvoyées par getSchoolInfo
        if (this.schoolInfo.length > 0) {
          if (!this.education_grade1) {
            this.education_grade1 = this.schoolInfo[0].education_grade;
          }
          if (!this.education_system1) {
            this.education_system1 = this.schoolInfo[0].education_system;
          }
          if (!this.training_type1) {
            this.training_type1 = this.schoolInfo[0].training_type;
          }

          if (!this.name) {
            this.name = this.schoolInfo[0].name;
            localStorage.setItem("leeeeee", this.schoolInfo[0].name)
          }
          if (!this.region) {
            this.region = this.schoolInfo[0].region;
          }
          if (!this.town) {
            this.town = this.schoolInfo[0].town;
          }
          if (!this.hood) {
            this.hood = this.schoolInfo[0].hood;
          }
          if (!this.owner) {
            this.owner = this.schoolInfo[0].owner;
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la configuration :",
          error
        );
      } finally {
        this.loading = false;
      }
    },

    watch: {
      // Écoutez les changements sur les variables du formulaire et mettez à jour les valeurs par défaut correspondantes
      training_type1(value) {
        this.training_type = value;
      },
      education_system1(value) {
        this.education_system = value;
      },
      education_grade1(value) {
        this.education_grade = value;
      },
    },

    async getSchoolInfo(schoolId) {
      try {
        const response = await getSchoolinfo(schoolId);

        if (response.status === 200) {
          this.schoolInfo = response.data;
          console.log("schoolInfo:", this.schoolInfo);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations de l'école :",
          error
        );
      }
    },

    async saveConfig() {

      this.loading = true;
      this.button = "disabled";


      let data = {


        is_verified: 1,
      };


      let grade = await pullGrades();
      console.log(grade);
      if (grade.status !== 200) {
        this.button = "";
        return;
      }

      let specialities = await pullSpecialities();
      if (specialities.status !== 200) {
        this.button = "";
        return;
      }

      let configId = this.configId;
      let config = await updateConfig(configId, data);

      if (config.status !== 200) {
        this.button = "";
        return;
      }



      if (this.schoolInfo.length > 0) {
        if (!this.education_grade1) {
          this.education_grade1 = this.schoolInfo[0].education_grade;
        }
        if (!this.education_system1) {
          this.education_system1 = this.schoolInfo[0].education_system;
        }
        if (!this.training_type1) {
          this.training_type1 = this.schoolInfo[0].training_type;
        }

        if (!this.name) {
          this.name = this.schoolInfo[0].name;
        }
        if (!this.region) {
          this.region = this.schoolInfo[0].region;
        }
        if (!this.town) {
          this.town = this.schoolInfo[0].town;
        }
        if (!this.hood) {
          this.hood = this.schoolInfo[0].hood;
        }
        if (!this.owner) {
          this.owner = this.schoolInfo[0].owner;
        }
      }

      // type  de paiment 
      const payment_type = this.owner === 'Private' ? 'SchoolFees' : 'govFees';
      localStorage.setItem('payment_type', payment_type);
      // Stockage des données dans le localStorage
      localStorage.setItem("training_type", this.training_type1);
      localStorage.setItem("education_grade", this.education_grade1);
      localStorage.setItem("education_system", this.education_system1);
      localStorage.setItem("name", this.name);
      localStorage.setItem("region", this.region);
      localStorage.setItem("town", this.town);
      localStorage.setItem("hood", this.hood);
      localStorage.setItem("owner", this.owner);
      localStorage.setItem("schoolId", this.school_id);




      this.$router.push("/");
      setTimeout(() => {
        this.button = "";
      }, 1000);
    },

  },
  created() {
    this.getAppConfig();
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.getAppConfig();
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style scoped>
.heart-animation {
  text-align: center;
  padding: 10px 25px;
  color: #fff;
  border: 2px solid white;
  box-shadow: 0 0 0 0 rgb(230, 235, 232);
  animation: battement 2s infinite cubic-bezier(0.66, 0, 0, 1);
  text-decoration: none;
  transition: 0.7s;
  cursor: pointer;
}

.heart-animation i {
  font-size: 30px;
  font-weight: bold;

}

.heart-animation:hover {
  text-align: center;


  color: #fff;

  border: 2px solid rgb(7, 156, 40);
  box-shadow: 0 0 0 0 rgb(28, 226, 107);
  animation: battement 2s infinite cubic-bezier(0.66, 0, 0, 1);
  text-decoration: none;

}

@keyframes battement {
  to {
    box-shadow: 0 0 0 30px rgba(255, 71, 84, 0);
  }
}

.horizontal {
  background-image: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      white,
      rgba(255, 255, 255, 0)) !important;
  transform: translateX(-40px);
}

.link {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  color: red;
}
</style>
