<template>

     

    <div class="main-transaction" >

        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
            <div>
                <span class="mt-4">
                    <Spinner></Spinner>
                </span>
            </div>
        </spinner>


        <div class="container-fluid" v-else-if="hasAdmin">

            <!--==================Alert==================-->
            <div class="card-api mt-4 mb-6 px-3"
                style="border: 1px solid rgba(250, 250, 250, 0.5); border-radius: 5px; background: #111c44;">
                <div class="card-api-content d-flex justify-content-between">
                    <div class="d-flex mt-3 pt-1">
                        <div class="key pe-2 me-3">
                            <i class="bi bi-exclamation-circle-fill fs-1 mt-3 text-danger"></i>
                        </div>
                        <div class="text mx-3">
                            <h6 style="font-size: 14px;"> {{ $t("transfer.info_title") }}</h6>
                            <p style="font-size: 14px;"> {{ $t("transfer.info_p") }}</p>
                            <p style="font-size: 14px; margin-top: -10px;">
                                <a style="color:#4f61c4 !important;" target="_blank" href="#">{{ $t("transfer.info_p2")
                                    }}</a>
                            </p>
                        </div>
                    </div>
                    <div class="button" style="align-items: center">
                        <argon-button data-bs-toggle="modal" data-bs-target="#exampleModal40" color="success" size="xl"
                            style="background: #75acff; width: 150px; margin-top: 20px;">
                            {{ $t("button.modify") }}
                        </argon-button>
                    </div>
                </div>
            </div>


            <!--================== Table ==================-->

            <div class="card px-0 mt-7">
                <div class="card-body px-0">
                    <div class="row">
                        <div class="col-md-6 px-0"></div>
                        <div class="col-md-6 text-end" style="padding-right: 30px">

                        </div>
                    </div>

                    <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                        <div>
                            <span class="mt-4">
                                <Spinner></Spinner>
                            </span>
                        </div>
                    </spinner>

                    <table class="table px-0 mt-6 mb-6" v-else-if="!table.length > 0">
                        <thead>
                            <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">


                                <th class=" border-0" style="text-decoration: none">
                                    {{ $t("transfer.amount") }}
                                </th>

                                <th class=" border-0">
                                    {{ $t("transfer.start_date") }}
                                </th>

                                <th class=" border-0 text-start px-0">
                                    {{ $t("transfer.end_date") }}
                                </th>

                                <th class="text-end border-0">
                                    {{ $t("transfer.status") }}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="list in table" :key="list.id" style="
                             position: relative;
                             border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                             ">

                                <td class=" border-0 px-4 ">
                                    <span class="amount">
                                        XAF {{ Number(list.Montant).toLocaleString() }}</span>
                                </td>

                                <td class="px-4 border-0 position-relative">
                                    {{ list.Date_debut }}

                                </td>

                                <td class=" ps-0 pe-7 border-0 position-relative">
                                    {{ list.Date_fin }}

                                </td>
                                <td class="text-end px-4 border-0 position-relative">
                                    {{ list.Statut }}
                                </td>
                            </tr>

                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <!--================== NO transaction ==================-->
                    <div class="else text-center pb-5" v-else>
                        <div class="container">
                            <div class="text mt-3">
                                <img class="image" src="@/assets/img/transaction.png" alt="" />
                                <h5 class="mt-4"> {{ $t("transfer.no_transfer") }} </h5>

                                <!---
                                <p class="mt-4 px-4">
                                    Utque proeliorum periti rectores primo catervas densas opponunt et fortes,
                                    deinde leves armaturas, post iaculatores
                                    ultimasque subsidiales acies, si fors adegerit, iuvaturas, ita praepositis urbanae.
                                </p>
                        -->

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="no-admin" v-else>
            <div class="container mt-5">
                <div class="text text-center mt-5">
                    <img class="image w-25" src="@/assets/img/admin.png" alt="" />
                    <h5 class="mt-4"> Cette page est strictement réservée aux administrateurs.</h5>

                    <!---
                                <p class="mt-4 px-4">
                                    Utque proeliorum periti rectores primo catervas densas opponunt et fortes,
                                    deinde leves armaturas, post iaculatores
                                    ultimasque subsidiales acies, si fors adegerit, iuvaturas, ita praepositis urbanae.
                                </p>
                        -->

                </div>
            </div>
            
        </div>

    </div>




</template>

<script>
import Spinner from "../components/Spinner.vue";
 
import ArgonButton from "@/components/ArgonButton";
import { getMembers } from "@/utils/services";
import { ref, onMounted } from "vue";


export default {
    name: "historique-Transaction",
    components: { Spinner,  ArgonButton },
    data() {
        return {
            isOffline: !navigator.onLine,
            table: [
                {

                    Montant: 150000,
                    Date_debut: "25/05/2024",
                    Date_fin: "25/05/2024",
                    Total: 45000,
                    Statut: "visible"
                },
                {

                    Montant: 150000,
                    Date_debut: "25/05/2024",
                    Date_fin: "25/05/2024",
                    Total: 45000,
                    Statut: "visible"
                },


            ],
        }
    },

    mounted() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        this.intervalId = setInterval(this.checkInternetConnection, 1000);
    },
    beforeUnmount() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);

        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },



    setup() {
        const members = ref([]);
        const loading = ref(false);

        const fetchMembers = async () => {
            try {
                loading.value = true;
                const result = await getMembers();
                if (!navigator.onLine) {
                    this.internet = false; // Pas de connexion Internet
                    this.no_internet = true; // Afficher le contenu pour pas de connexion Internet
                    alert(
                        "Vous n'êtes pas connecté à Internet. Veuillez vérifier votre connexion et réessayer."
                    );
                    return;
                }

                if (result.status === 200) {
                    members.value = result.data;
                    localStorage.setItem("members", JSON.stringify(result.data));

                    this.internet = true;
                    this.no_internet = false;
                } else {
                    console.error(
                        "Erreur lors de la récupération des membres :",
                        result.error
                    );
                }
            } catch (error) {
                console.error(
                    "Erreur inattendue lors de la récupération des membres :",
                    error
                );
            } finally {
                loading.value = false;
            }
        };

        onMounted(() => {
            fetchMembers();
        });



        return {
            members,
            loading


        };
},




    method: {


        updateOnlineStatus() {
            this.isOffline = !navigator.onLine;
            this.checkInternetConnection();
        },

        async checkInternetConnection() {
            try {
                const response = await fetch("https://www.google.com", {
                    mode: "no-cors",
                });
                if (response.ok || response.type === 'opaque') {
                    this.isOffline = false;
                } else {
                    this.isOffline = true;
                }
            } catch (error) {
                this.isOffline = true;
            }
        },
    },

    computed: {

        hasAdmin() {
            return this.members.some(member => member.is_staff);
        }

    }


};


</script>

<style>
#form {
    background: none;
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;
}

#dropdown2 .btn {
    height: 45px;
    border: 1px solid rgba(250, 250, 250, 0.6);
    width: 200px;

}

#dropdown2 .dropdown-menu li {
    padding: 7px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    color: white;
}

#dropdown2 .dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}




#card .card1 {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    color: white;
    border-radius: 10px;
}

#card .card1 span {
    font-size: 18px;
}

#card .card1 .price {
    font-size: 25px;
    font-weight: bold;
}

.table tbody tr td {
    padding-top: 13px;
    padding-bottom: 13px;
}

#span-table {
    width: 150px;
}
</style>