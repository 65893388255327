<template>
  <div class="container-fluid hov py-4">
    <div v-if="quota_day" class="card-api mt-4 mb-6 px-3"
      style="border: 1px solid rgba(206, 85, 66, 0.5); border-radius: 5px; background: #111c44;">
      <div class="card-api-content d-flex justify-content-between">
        <div class="d-flex mt-3 pt-1">
          <div class="key pe-2 me-3">
            <i class="bi bi-exclamation-circle-fill fs-1 mt-3 text-danger"></i>
          </div>
          <div class="text mx-3">
            <h6 style="font-size: 14px;">Votre quota est atteint pour aujourd'hui</h6>
            <p style="font-size: 14px;">Vos 500 messages de la journéé sont épuisés. Vous pouvez attendre demain ou
              choisir une autre option tarifaire.</p>
            <p style="font-size: 14px; margin-top: -10px;">
              <a style="color:#4f61c4 !important;" target="_blank" href="#">Changer d'option tarifaire</a>
            </p>
          </div>
        </div>
      </div>
    </div>


    <div v-if="eska" class="card-api mt-4 mb-6 px-3 pb-3"
      style="border: 1px solid rgba(206, 85, 66, 0.5); border-radius: 5px; background: #111c44;">
      <div class="card-api-content d-flex justify-content-between">
        <div class="d-flex mt-3 pt-1">
          <div class="key pe-2 me-3">
            <i class="bi bi-exclamation-circle-fill fs-1 mt-3 text-danger"></i>
          </div>
          <div class="text mx-3">
            <h6 style="font-size: 14px;">Ce numéro n'est pas sur eska</h6>
            <p style="font-size: 14px;">Sed maximum est in amicitia parem esse inferiori.
              Saepe enim excellentiae quaedam sunt,
              qualis erat Scipionis in nostro, ut ita dicam, grege.</p>
            <argon-button color="success" size="xl" style="background: #75acff; width: 150px; margin-bottom: 20px;">
              Inviter
            </argon-button>
          </div>
        </div>
        <div title="Fermer la modale" class="close"><i id="close" @click="close" class="fa fs-3 mt-2 fa-times"></i>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body d-flex">
        <form class="w-80" style="margin:0 auto;" @submit.prevent="sendMessage">
          <div class="card-header p-0 text-start">
            <h6>Envoyer un message</h6>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="inputId" style="color: grey;">Destinataire</label>
                <textarea
                  style="border-radius: 0; background: transparent; color: white; resize: none; overflow: hidden;"
                  class="form-control" v-model="recipientQuery" @input="filterStudents" placeholder="..."
                  rows="2"></textarea>
                <ul v-if="filteredStudents.length > 0" class="suggestions-list ps-0">
                  <li id="li" v-for="student in filteredStudents" :key="student.name" @click="selectStudent(student)">
                    <div class="d-flex li">
                      <div class="left">
                        <p>{{ student.name }}</p> <br>
                        <h6 class="bg-gradient-info py-1 px-2" style="margin-top:-30px;">{{ student.parentPhone }}</h6>
                      </div>
                      <div class="right">
                        {{ student.class }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mb-3">
                <label for="message" style="color: grey;">Message</label>
                <rich-text-editor style="min-height:200px;" v-model="message"></rich-text-editor>
              </div>
              <div class="row mb-3 ps-2 text-start">
                <button id="send_message" type="submit" class="btn btn-success w-30">
                  Envoyer
                  <i class="fa fa-paper-plane text-light" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div class="col-6">
              <div class="top">
                <label for="media" style="color: grey;">Ajouter un média</label>
                <input style="height: 50px; padding-top: 13px" type="file" id="media" @change="handleFileUpload"
                  class="w-80 form-control">
              </div>
              <div class="media-preview w-80 mt-3 ps-0" v-if="mediaFile">
                <h6>Fichier ajouté :</h6>
                <div class="media-item">
                  <div class="media-info">
                    <img v-if="isImage(mediaFile)" :src="mediaFile.preview" class="img-thumbnail"
                      alt="Aperçu du fichier">
                    <span v-else>{{ mediaFile.name }}</span>
                  </div>
                  <button @click="removeFile" class="btn btn-danger" id="btn-danger" title="Supprimer le fichier">
                    <i class="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
              <div id="alerte"
                class="alert w-80 ps-0 mt-3 d-flex justify-content-between align-items-center text-center"
                v-if="errorMessage">
                <span>{{ errorMessage }}</span>
                <button type="button" class="btn-close" aria-label="Close" @click="closeErrorMessage">
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="list mb-10">
        <ListMessage />
      </div>
    </div>

    <!-- Modal pour Eska -->



  </div>

</template>

<script>
import RichTextEditor from '../components/TextEditor.vue';
import ListMessage from './ListMessage.vue';
import ArgonButton from "@/components/ArgonButton";

/**
 * The main Vue component for the Message view in the Eska application.
 * This component handles the creation and sending of messages, including the ability to upload media files.
 * It also manages the display of a list of messages and the Eska modal.
 */
export default {
  name: "Message_Vue",
  components: { RichTextEditor, ListMessage, ArgonButton },
  data() {
    return {
      message: "",
      eska: false,
      mediaFile: null,
      errorMessage: "",
      quota_day: false,
      quota_month: false,
      recipientQuery: "",
      filteredStudents: [],
      showEskaModal: false, // Ajout pour gérer l'affichage de la modal
      students: [
        { name: "John Doe", class: "6ème", parentPhone: "123-456-7890", eska: "oui" },
        { name: "Jane Smith", class: "5ème", parentPhone: "987-654-3210", eska: "non" },
        { name: "Léo Martial", class: "4ème", parentPhone: "237-654-3210", eska: "oui" },
        // Ajoutez plus d'élèves ici
      ],
    };
  },
  methods: {
     adjustTextareaHeight(event) {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  },

    close() {
      this.eska = false;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const isValidImage = file.type.match(/image\/(jpg|jpeg|gif|png)/) && file.size <= 1048576; // 1MB
        const isValidVideo = file.type === 'video/mp4' && file.size <= 5242880; // 5MB

        if (isValidImage || isValidVideo) {
          const reader = new FileReader();
          reader.onload = (e) => {
            file.preview = e.target.result;
            this.mediaFile = file;
            this.errorMessage = "";
          };
          reader.readAsDataURL(file);
        } else {
          this.errorMessage = "Seules les images (jpg, jpeg, gif, png) de taille maximale 1 Mo et les vidéos (mp4) de taille maximale 5 Mo sont autorisées.";
          this.removeFile();
        }
      }
    },
    removeFile() {
      this.mediaFile = null;
      document.getElementById("media").value = "";
    },
    isImage(file) {
      return file.type.startsWith('image/');
    },
    closeErrorMessage() {
      this.errorMessage = "";
    },
    sendMessage() {
      // Logic to send the message and media file
      const formData = new FormData();
      formData.append("message", this.message);
      if (this.mediaFile) {
        formData.append("media", this.mediaFile);
      }

      // Example of sending the form data to a server
      fetch("/api/send-message", {
        method: "POST",
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          console.log("Message sent successfully:", data);
          // Reset the form
          this.message = "";
          this.mediaFile = null;
          document.getElementById("media").value = "";
        })
        .catch(error => {
          console.error("Error sending message:", error);
        });
    },
    filterStudents() {
      const query = this.recipientQuery.toLowerCase();
      if (query === "") {
        this.filteredStudents = [];
      } else {
        this.filteredStudents = this.students.filter(student => student.name.toLowerCase().includes(query));
      }
    },
    selectStudent(student) {
  this.recipientQuery = `${student.name}\n${student.parentPhone} - ${student.class}`;
  this.filteredStudents = [];
  if (student.eska.toLowerCase() === 'non') {
    this.eska = true;
  }
}
  }
};
</script>

<style scoped>
.container-fluid {
  background-color: transparent;
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.card-header {
  margin-top: 50px;
  margin-bottom: 30px;
}

.card-body {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.media-preview {
  flex: 0 0 30%;
}

.media-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.media-info {
  display: flex;
  align-items: center;
}

.img-thumbnail {
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px;
}

.btn-success:hover {
  background: transparent;
}

#exampleInputEmail1 {
  background: none;
}

#icon i {
  cursor: pointer;
}

#send_message {
  height: 50px;
  margin-top: 30px;
  border: 1px solid #2dceb1;
}

#btn-danger {
  transform: translateY(10px);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  position: relative;
  opacity: 0.8;
}

#btn-danger .bi {
  position: absolute;
  left: 13px;
  top: 10px;
  font-weight: bold;
}



.alert {
  max-width: 80%;
  margin: 0 auto;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  background: white;
  color: red;
  opacity: 1;
  border-radius: 50%;
}

#alerte {
  background: rgba(0, 0, 0, 0.6);
  transform: translateX(-50px);
  padding: 10px;
  opacity: 0.4;
  border: 2px solid #ce5542;
  position: relative;
  color: #ce5542;

}

.hov {
  position: relative;
}

#li {
  border: 1px solid rgba(250, 250, 250, 0.4);
  padding: 10px 15px;
  list-style: none;
  transition: 0.6;
}

#li:hover {
  background: rgba(0, 0, 0, 0.6);
}

#li .li {
  justify-content: space-between;
}

#close {
  cursor: pointer;
  transition: 0.3s;

}

#close:hover {
  color: #ce5542;
}
textarea {
  min-height: 38px;
  height: auto;
}
</style>
