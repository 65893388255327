<template>
  <div class="top" style="width: 99%; padding-left: 15px">
    <div class="card mx-3 mt-7">
      <div class="card-body px-0 pt-0 mt-4">
        <div class="row">
          <div class="col-md-7" style="padding-left: 35px"></div>
          <div class="col-md-5 text-end" style="padding-right: 30px"></div>
        </div>
        <div class="table-responsive p-0 mt-5 mb-5">
          <spinner
            class="text-center mt-4 mb-8"
            v-if="loading"
            style="height: 200px"
          >
            <div>
              <span class="mt-4"><Spinner></Spinner></span>
            </div>
          </spinner>

          <div
            class="table-responsive p-0 mt-5 mb-5"
            v-else-if="table.length > 0"
          >
            <table class="table align-items-center mb-0">
              <thead>
                <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                  <th scope="col" style="text-transform: none" class="border-0">
                    {{ $t("receipt_list.last_name") }}  
                  </th>
                  <th scope="col" class="border-0">{{ $t("receipt_list.grade") }}</th>
                  <th scope="col" class="border-0">{{ $t("receipt_list.number") }}</th>
                  <th scope="col" class="text-end border-0">{{ $t("receipt_list.receipt") }}</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(list, index) in paginatedTable"
                  :key="index"
                  style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)"
                >
                  <td class="px-4 border-0">
                    {{ list.last_name }} {{ list.first_name }}
                  </td>
                  <td class="border-0">
                    {{ list.grade }}
                  </td>
                  <td class="px-4 border-0">{{ list.registration_number }}</td>
                  <td class="px-4 border-0 text-end opacity-6">
                    <a
                      title="Cliquer pour visualier le reçu"
                      target="_blank"
                      :href="list.receipt_url"
                    >
                      <i class="fas fa-file-pdf" aria-hidden="true"></i>
                      Receipt</a
                    >
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </table>

            <nav aria-label="...">
              <ul class="pagination">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button
                    class="page-link"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    style="background: none; color: white"
                  >
                    <i class="bi bi-chevron-left"></i>
                  </button>
                </li>
                <li
                  v-for="page in totalPages"
                  :key="page"
                  class="page-item"
                  :class="{ active: currentPage === page }"
                >
                  <button
                    class="page-link fw-bold"
                    style="background: none"
                    @click="setCurrentPage(page)"
                  >
                    {{ page }}
                  </button>
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    class="page-link"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    style="
                      background: none;
                       
                      color: white;
                    "
                  >
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <div class="else text-center pb-5" v-else>
            <div class="container">
              <div class="text mt-3">
                <img class="image" src="@/assets/img/paiementlist.png" alt="" />
                <h5 class="mt-4">Aucun Reçus envoyés.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiConfig from "@/config";
import { getclassroomreport } from "@/utils/services";
import Spinner from "../components/Spinner.vue";

export default {
  name: "Classroom-Receipt",

  data() {
    return {
      classroom_id: this.$route.params.classId,
      table: [],
      loading: false,
      settled: apiConfig.SETTLED,
      accepted: apiConfig.ACCEPTED,
      overdue: apiConfig.OVERDUE,
      pending: apiConfig.PENDING,
      paid: apiConfig.PAID,
      currentPage: 1,
      itemsPerPage: 50,
    };
  },

  mounted() {
    this.fetchClassroomReport(this.classroom_id);
  },
  components: {
    Spinner,
  },
  computed: {
    paginatedTable() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.table.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.table.length / this.itemsPerPage);
    },
  },

  methods: {
    async fetchClassroomReport(classroom_id) {
      try {
        this.loading = true;
        // Appeler la fonction pour récupérer les informations de la classe
        const response = await getclassroomreport(classroom_id);
        if (response) {
          this.table = response.student_receipt;
        }
      } catch (error) {
        console.error("Error fetching classroom report:", error);
      } finally {
        this.loading = false;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
  },

  props: {
    Students: {
      type: Array,
      default: () => [],
    },
    configInstallments: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    // Vérifiez si la configuration a déjà été appliquée lors de la création du composant
    const paymentConfigApplied = localStorage.getItem("paymentConfigApplied");
    if (paymentConfigApplied === "true") {
      this.showOverlay = false;
    }
    this.pendingStudentsPaymentsList = this.pendingStudentsPayments;
  },
};
</script>

<style scoped>
.main {
  position: relative;
}
.card {
  min-height: 48vh;
}

#avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transition: 300ms ease;
}

#avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
  user-select: none;
  pointer-events: none;
}

#avatar:hover {
  padding: 10px;
  transform: rotate(10deg);
}
.custom-select {
  position: relative;
}
.tabble-buttun {
  height: 40px;
  margin-top: 20px;
}
.tabble-buttun span i {
  font-size: 18px;
  cursor: pointer;
  transform: translateY(10px);
}

.pagination {
  margin-top: 30px;
  display: flex;
  width: max-content;
}

.pagination button {
  margin: 0 5px;
  cursor: pointer;
  background-color: #081739;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 20px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
