<template>

 
  <div class="fixed-plugin">
    <a class="px-3 py-2 fixed-plugin-button text-dark position-fixed" @click="toggle">
      <i class="py-2 fa fa-cog"></i>
    </a>
    <div class="shadow-lg card" style="border-left: 2px solid rgba(250, 250, 250, 0.4)">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="mt-4" @click="toggle" :class="this.$store.state.isRTL ? 'float-start' : 'float-end'">
          <button style="border:1px solid white; height: 40px; width: 40px; border-radius: 50%;"
            class="p-0 btn btn-link text-light fixed-plugin-close-button">
            <i class="bi bi-x-lg"></i>
          </button>
        </div>
      </div>
      <hr class="my-1 horizontal dark" />
      <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
        <div>
          <span class="mt-4">
            <Spinner></Spinner>
          </span>
        </div>
      </spinner>

      <div class="no-admin mt-7 py-3 text-center"   v-else-if="!hasAdmin">
        <div class="text text-center mt-5">
                    <img class="image w-50" src="@/assets/img/admin.png" alt="" />
                    <h6 class="mt-4"> Cette page est strictement réservée aux administrateurs.</h6>

                    <!---
                                <p class="mt-4 px-4">
                                    Utque proeliorum periti rectores primo catervas densas opponunt et fortes,
                                    deinde leves armaturas, post iaculatores
                                    ultimasque subsidiales acies, si fors adegerit, iuvaturas, ita praepositis urbanae.
                                </p>
                        -->

                </div>
      </div>

      <div class="pt-0 card-body pt-sm-3" v-else >
        <p class="mt-2 text-sm d-block">La somme minimale de transfert s'élève à 100 000 XAF.</p>
        
        
        <div class="mt-3 d-flex">
          <h6 class="mb-0">Supporter les frais</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input class="mt-1 form-check-input" type="checkbox" id="supportFeesCheckbox"
              v-model="isSupportFeesModalVisible" />
              
          </div>
        </div>
        <p class="mt-2 text-sm d-block">Les frais de transactions seront prélevés à vous plutôt qu’aux parents. </p>
        <hr class="horizontal dark my-4" />

        <div class="mt-3 d-flex">
          <h6 class="mb-0">Paiement petite tranche</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input class="mt-1 form-check-input" type="checkbox" id="smallInstallmentCheckbox"
            v-model="isSmallInstallmentModalVisible"/>
              
          </div>
        </div>
        <p class="mt-2 text-sm d-block">Les parents pourront payer à leur rythme tant qu’ils restent dans les délais.
          Vous aurez les détails de chaque paiement, ainsi que le récapitulatif.</p>
        <hr class="horizontal dark my-4" />

        <div class="mt-3 d-flex">
          <h6 class="mb-0">Informations bancaires</h6>
          
        </div>
        <p class="mt-2 text-sm d-block"><strong>Banque :</strong> {{ bank_name }} </p>
        
        <hr class="horizontal dark my-4" />

      </div>

    </div>

    <!-- Include the modals -->
    <SupportFeesModal :isVisible="isSupportFeesModalVisible" @update:isVisible="updateSupportFeesModalVisibility"
      @close="resetSupportFeesCheckbox" />
    <SmallInstallmentModal :isVisible="!isSmallInstallmentModalVisible"
      @update:isVisible="updateSmallInstallmentModalVisibility" @close="resetSmallInstallmentCheckbox" />
  </div>
</template>


<script>
import SupportFeesModal from '@/views/modal/SupportFeesModal.vue';
import SmallInstallmentModal from '@/views/modal/SmallInstallmentModal.vue';
import { getconnection, getMembers } from "@/utils/services";
import { ref, onMounted } from "vue";
import Spinner from "@/views/components/Spinner.vue";
 

export default {
  name: 'xschool-configurator',
  props: ['toggle'],
  components: {
    SupportFeesModal,
    SmallInstallmentModal,
    Spinner
  },
  data() {
    return {
      isSupportFeesModalVisible: false,
      isSmallInstallmentModalVisible: true,
      bank_name: "",
      schoolId: "",
      fees_on_payer: null,
      allow_split_payment: null,
     


    };
  },
  setup() {
    const members = ref([]);
    const loading = ref(false);

    const fetchMembers = async () => {
      try {
        loading.value = true;
        const result = await getMembers();
        if (!navigator.onLine) {
          this.internet = false; // Pas de connexion Internet
          this.no_internet = true; // Afficher le contenu pour pas de connexion Internet
          alert(
            "Vous n'êtes pas connecté à Internet. Veuillez vérifier votre connexion et réessayer."
          );
          return;
        }

        if (result.status === 200) {
          members.value = result.data;
          localStorage.setItem("members", JSON.stringify(result.data));
      
          this.internet = true;
          this.no_internet = false;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchMembers();
    });



    return {
      members,
      loading


    };


  },


     

    methods: {
    updateSupportFeesModalVisibility(isVisible) {
      this.isSupportFeesModalVisible = !isVisible;
    },
    resetSupportFeesCheckbox() {
      this.isSupportFeesModalVisible = false;
    },
    updateSmallInstallmentModalVisibility(isVisible) {
      this.isSmallInstallmentModalVisible = isVisible;
    },
    resetSmallInstallmentCheckbox() {
      this.isSmallInstallmentModalVisible = true;
    },
    async fetchSchoolInfo() {
      let data = {
        school_id: this.schoolId
      }

      try {
        let response = await getconnection(data);
        this.bank_name = response[0].bank_slug;
        this.fees_on_payer = response[0].fees_on_payer;
        this.allow_split_payment = response[0].allow_split_payment;
      } catch (error) {
        console.error("Erreur:", error);
        this.failedMessage = "Une erreur s'est produite lors de la recuperation des informations.";
      }
    },

     
  },
  computed: {
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },

  
    hasAdmin() {
      return this.members.some(member => member.is_staff);
    }
 

  },
  mounted() {
    this.schoolId = localStorage.getItem("schoolId");
 
    if (this.schoolId) {
      this.fetchSchoolInfo();
     
    } else {
      console.error("schoolId n'est pas défini dans localStorage");
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.sidenavTypeOnResize);
    window.addEventListener('load', this.sidenavTypeOnResize);
  },
};
</script>

<style scoped>


</style>
