<template>
  <div class="notification-bar transition-effect" id="notificationBar" style="display: none">
    <div class="header text-center mb-0">
      <div class="row">
        <span class="col-md-4 pt-4 fw-bold text-start" style="padding-left:18px ">Notifications</span>
        <span class="col-md-4 text-dark fw-bold"></span>
        <span class="close_modal pt-3 col-md-4 pe-5 text-dark text-end  fw-bold" title="Fermé">
          <i @click="toggleNotificationBar" class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>
    <div class="body p-0">
      <div class="body-content border-0">
        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
          <div>
            <span class="mt-4">
              <Spinner></Spinner>
            </span>
          </div>
        </spinner>
        <div class="" v-else-if="table.length > 0">
          <!-- Aujourd'hui -->
          <div v-if="groupedNotifications.today.length > 0">
             
            <div class="message actif text-justify " v-for="list in groupedNotifications.today" :key="list.id">
              <div class="row">
                <div class="col-md-2 ps-2 profil">
                  <img src="@/assets/img/logo-ct.png" alt="" />
                </div>
                <div class="col-md-10">
                  <div class="title-notif mt-2">
                    <strong>{{ list.title }}</strong>
                  </div>

                  <span :class="{ 'new-notification': !isRead(list.id), 'read-notification': isRead(list.id) }"></span>
                  <div class="text mt-4 mb-0">
                    {{ list.content }} <strong>{{ list.username }}</strong>
                  </div>
                  <div class="video p-0 mt-0 " v-if="list_video">
                    <video width="100%" controls>
                      <source src="@/assets/img/video.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="image mt-2" v-if="list.image">
                    <img :src="list.image" alt="" />
                  </div>

                  <div class="button d-flex p-0 ">
                    <div class="time">{{ formatDate(list.created_on) }}</div>
                    <button v-if="list.button" class="fw-bold opacity-9">Classroom</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Hier -->
          <div v-if="groupedNotifications.yesterday.length > 0">
            <h3 class="notification-group-title">Hier</h3>
            <div class="message actif text-justify mt-2" v-for="list in groupedNotifications.yesterday" :key="list.id">
              <notification-content :list="list" :isRead="isRead" :formatDate="formatDate" />
            </div>
          </div>

          <!-- 4 derniers jours -->
          <div v-if="groupedNotifications.lastFourDays.length > 0">
             
            <div class="message actif text-justify " v-for="list in groupedNotifications.lastFourDays"
              :key="list.id">
              <div class="row">
                <div class="col-md-2 ps-2 profil">
                  <img src="@/assets/img/logo-ct.png" alt="" />
                </div>
                <div class="col-md-10">
                  <div class="title-notif mt-2">
                    <strong>{{ list.title }}</strong>
                  </div>

                  <span :class="{ 'new-notification': !isRead(list.id), 'read-notification': isRead(list.id) }"></span>
                  <div class="text mt-4 mb-0">
                    {{ list.content }} <strong>{{ list.username }}</strong>
                  </div>
                  <div class="video p-0 mt-0 " v-if="list_video">
                    <video width="100%" controls>
                      <source src="@/assets/img/video.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="image mt-2" v-if="list.image">
                    <img :src="list.image" alt="" />
                  </div>

                  <div class="button d-flex p-0 ">
                    <div class="time">{{ formatDate(list.created_on) }}</div>
                    <button v-if="list.button" class="fw-bold opacity-9">Classroom</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Plus ancien -->
          <div v-if="groupedNotifications.older.length > 0">
           
            <div class="message actif text-justify " v-for="list in groupedNotifications.older" :key="list.id">
              <div class="row">
                <div class="col-md-2 ps-2 profil">
                  <img src="@/assets/img/logo-ct.png" alt="" />
                </div>
                <div class="col-md-10">
                  <div class="title-notif mt-2">
                    <strong>{{ list.title }}</strong>
                  </div>

                  <span :class="{ 'new-notification': !isRead(list.id), 'read-notification': isRead(list.id) }"></span>
                  <div class="text mt-4 mb-0">
                    {{ list.content }} <strong>{{ list.username }}</strong>
                  </div>
                  <div class="video p-0 mt-0 " v-if="list_video">
                    <video width="100%" controls>
                      <source src="@/assets/img/video.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="image mt-2" v-if="list.image">
                    <img :src="list.image" alt="" />
                  </div>

                  <div class="button d-flex p-0 ">
                    <div class="time">{{ formatDate(list.created_on) }}</div>
                    <button v-if="list.button" class="fw-bold opacity-9">Classroom</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="button text-center">
            <button class="mt-2" @click="notification">
              <span class="opacity-9">Voir toutes les notifications</span>
            </button>
          </div>
        </div>
        <div class="no-notif" v-else>
          <div class="container-fluid">
            <div class="content text-center">
              <img style="width:110px;" class="image" src="@/assets/img/notiff.png" alt="" />
              <h5>Aucune notification</h5>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav id="navbarBlur" v-bind="$attrs" :class="this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 mb-4 shadow-none border-radius-xl" data-scroll="true">
    <div class="px-2 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" textWhite="text-white" />
      <div id="navbar" :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4">
        <div :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'" class="pe-md-3 d-flex">
          <div class="bell mt-4 px-2" id="bell">
            <i title="Notification" class="bi bi-bell-fill" @click="toggleNotificationBar"></i>
            <span v-if="pendingNotificationsCount>0" >{{ pendingNotificationsCount }}</span>
          </div>
          <div class="row mt-2 px-2">
            <div class="input-group desktop">
              <span class="input-group-text text-body">
                <i aria-hidden="true" class="fas fa-search"></i>
              </span>
              <input :placeholder="'Type here...'" class="form-control" type="text" />
            </div>
            <div class="input-group translate mt-4" style="height: 45px">
              <span class="input-group-text text-body flag-custom">
                <i aria-hidden="true" :class="`fi fi-${current}`"></i>
              </span>
              <select v-model="$i18n.locale" class="form-select text-uppercase" @change="langChange($event)">
                <option class="text-uppercase" v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`"
                  :value="locale">
                  {{ locale }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <ul class="navbar-nav justify-content-end mt-4 nav-logout">
          <li class="nav-item d-flex align-items-center" :title="$t('sidebar.logout')">
            <a style="cursor: pointer; width: 50px; height: 50px; border-radius: 100%; padding-left: 15px;"
              v-if="isLoggedIn" class="px-3 nav-link font-weight-bold text-dark bg-white" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop1">
              <span class="d-sm-inline d-none" style="font-size: 22px"><i class="bi bi-box-arrow-right"></i></span>
            </a>
            <router-link v-else :to="{ name: 'Signin' }" class="px-0 nav-link font-weight-bold text-white"
              target="_blank">
              <i :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-2'" class="fa fa-user"></i>
              <span class="d-sm-inline d-none">Sign In</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>





<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import Spinner from "../../components/Spinner.vue";
import { isLoggedIn, logoutUser } from "@/utils/auth";
import { getnotifications } from "@/utils/services";
import { format, isToday, isYesterday, subDays, isAfter } from 'date-fns';


export default {
  name: "xschool-navbar",
  data() {
    return {
      showMenu: false,
      current: this.$i18n.locale,
      table: [],
      loading: false,
      readNotifications: new Set(),
    };
  },
  props: ["minNav", "textWhite"],
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    isLoggedIn() {
      return isLoggedIn();
    },
    isRead(notificationId) {
      return this.readNotifications.has(notificationId);
    },
    notification() {
      this.toggleNotificationBar();
      this.$router.push({
        path: "/notifications",
        query: { forceReload: true },
      });
    },
    langChange(event) {
      if (event.target.value === "en") {
        this.current = "us";
      } else {
        this.current = event.target.value;
      }
    },
    async logout() {
      try {
        logoutUser();
      } catch (err) {
        alert(`Error: ${err}`);
      }
    },
    toggleNotificationBar() {
      const notificationBar = document.getElementById("notificationBar");
      notificationBar.style.display =
        notificationBar.style.display === "none" ? "block" : "none";
      notificationBar.classList.toggle("show");

      // Marquer les notifications affichées comme lues
      if (notificationBar.style.display === "block") {
        this.markAllAsRead();
      }
    },
    async fetchnotifications() {
      try {
        this.loading = true;
        let notifications = await getnotifications();
        
        notifications.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
        
        this.table = notifications.slice(0, 11);
      } catch (error) {
        console.error("Error fetching notifications data:", error);
      } finally {
        this.loading = false;
      }
    },
    groupNotifications() {
      const today = new Date();

      const fourDaysAgo = subDays(today, 4);

      const grouped = {
        today: [],
        yesterday: [],
        lastFourDays: [],
        older: []
      };

      this.table.forEach(notification => {
        const notifDate = new Date(notification.created_on);
        if (isToday(notifDate)) {
          grouped.today.push(notification);
        } else if (isYesterday(notifDate)) {
          grouped.yesterday.push(notification);
        } else if (isAfter(notifDate, fourDaysAgo)) {
          grouped.lastFourDays.push(notification);
        } else {
          grouped.older.push(notification);
        }
      });

      return grouped;
    },

    formatDate(dateString) {
      return format(new Date(dateString), 'dd/MM/yyyy HH:mm');
    },
    markAsRead(notificationId) {
      this.readNotifications.add(notificationId);
      this.saveReadNotifications();
    },

    markAllAsRead() {
      this.table.forEach(notification => {
        if (notification.status === "Pending") {
          this.readNotifications.add(notification.id);
        }
      });
      this.saveReadNotifications();
    },

    saveReadNotifications() {
      localStorage.setItem('readNotifications', JSON.stringify([...this.readNotifications]));
    },

    loadReadNotifications() {
      const saved = localStorage.getItem('readNotifications');
      if (saved) {
        this.readNotifications = new Set(JSON.parse(saved));
      }

    },


  },
  components: {
    Breadcrumbs,
    Spinner
  },
  computed: {
    currentRouteName() {
      return this.$route.name;

    },
    groupedNotifications() {
      return this.groupNotifications();
    },
    ///===========filtre ========
    pendingNotificationsCount() {
      return this.table.filter(notification =>
        notification.status === "Pending" && !this.readNotifications.has(notification.id)
      ).length;
    },
    limitedNotifications() {
      return this.table.slice(0, 6);
    }
  },
  mounted() {
    this.fetchnotifications();
    this.loadReadNotifications();
  }
};
</script>




<style lang="scss" scoped>
#navbar {
  .form-select {
    background-image: none !important;
  }

  .flag-custom {
    width: 43px !important;
    margin-left: 8px;
  }

  .input-group {
    width: auto;
  }
}

.desktop {
  display: none;
}

@media (max-width: 780px) {
  .desktop {
    display: none;
  }

  .translate {
    margin-top: 50px;
  }

  .nav-logout {
    transform: translateY(-30px);
  }
}

#bell {
  position: relative;
}

#bell i {
  font-size: 40px;
  cursor: pointer;
  color: white;
  transition: 0.3s;
}

#bell i:hover {
  color: #e2e1e1;
}

#bell span {
  position: absolute;
  right: 8px;
  color: white;
  background: #f5484c;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 13px;
  padding-left: 6px;
  font-weight: bold;
}

.notification-bar {
  position: absolute;
  width: 450px;
  top: 100px;
  right: 10px;
  height: 85vh;
  background: #0b122c;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid #111c44;

  z-index: 120;
}

.notification-bar .header {
  height: 70px;
  width: 100%;
  background: #0b122c;
  border-radius: 10px 0px 0px 0px;
}

.notification-bar .header span {
  color: white;
}

.notification-bar .button {
  margin-top: 20px;
}

.notification-bar .button button {
  border: none;
  height: 40px;
  width: 250px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  transition: 0.3s;
  box-shadow:
    rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.notification-bar .button button:hover {
  transform: translateY(-2px);
}

.notification-bar .header .close_modal {
  font-size: 22px;
  opacity: 0.6;
}

.notification-bar .header .close_modal i {
  cursor: pointer;
  color: white;
}

.notification-bar .body {
  padding: 0;
  height: 75vh;


}

.notification-bar .body .body-content {
  overflow-y: scroll;
  padding: 20px 10px;
  height: 100%;
  overflow-x: hidden;
  border: 1px solid rgba(250, 250, 250, 0.2);


}


.notification-bar .body .body-content .message {

  padding: 20px 10px 20px 10px;
  color: white;
  width: 100%;
  display: block;
  height: auto;
  font-size: 14px;
  border-radius: 0;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  border: 1px solid rgba(250, 250, 250, 0.2);
  border-radius: 3px;
  background: #111c44;
  margin-top: -20px;
  margin-bottom:25px;
}

.notification-bar .body .body-content .message .profil img {
  width: 50px;
  border-radius: 50%;
  margin-left: -2px;
}

.notification-bar .body .body-content .message .title-notif {
  font-size: 16px;
  transform: translateY(5px);
  text-align: left;
  color: white;
}

.new-notification {
  background-color: #4CAF50;
  /* vert */
}

.read-notification {
  background-color: #9E9E9E;

}


.notification-bar .body .body-content .message span {
  position: absolute;
  display: block;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 10px;
  right: 10px;
}



.notification-bar .body .body-content .message .text {
  font-size: 14px;
  color: white;
}

.notification-bar .body .body-content .message .image {
  position: relative;
}

.notification-bar .body .body-content .message .image img {
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;
  image-rendering: pixelated;
}


.notification-bar .body .body-content .message .button button {
  width: auto;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  justify-content: space-between;

}

.notification-bar .body .body-content .message .span {
  background: white;
}

.notification-bar .body .body-content .message:hover {
  transform: translateY(-3px);
}
</style>
